PageApp.Ent.AuctionPausedModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (models, silently) {
    if (!models) return
    var content = {
      auctionPausedMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_AUCTION_PAUSED')
    }
    this.set(content, { silent: silently })
  }
})
