PageApp.Ent.MaxBidModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (silently) {
    var maxBidFormatted = myApp.ent.inPlay.attributes.userBiddingData.maxBidAmountFormatted
    var buttonText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CONFIRM_TEXT')
    var placeHolderMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_ENTER_MAX_BID_TEXT')
    var currentMaxBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CURRENT_MAX_BID_TEXT')

    // BD-3635: Request has been made to remove the max bid field from the webcast inplay item across the board. Auctioneers reported it being used
    // exclusively instead of the bid button by some users
    // Initially, this will be implemented by hiding the field in case anybody shouts with a legitimate reason for it to be reinstated.
    // (rather than ripping out all related code at this stage). The field is hidden (display:none) within the template.
    var displayMaxBidBox = 'inline'
    var displayMaxBid = maxBidFormatted ? 'inline-block' : 'none'

    var buttonState = 'disabled' // TODO determine initial state
    var inputState = ''
    if (!myApp.ent.user.isLoggedIn() || myApp.ent.userRegistration.attributes.status !== 2) {
      buttonState = 'disabled'
      inputState = 'disabled'
      displayMaxBidBox = 'none'
    }

    var content = {
      displayMaxBidBox: displayMaxBidBox,
      displayMaxBid: displayMaxBid,
      maxBidFormatted: maxBidFormatted,
      buttonState: buttonState,
      inputState: inputState,
      placeHolderMessage: placeHolderMessage,
      buttonText: buttonText,
      currentMaxBidText: currentMaxBidText
    }

    this.set(content, { silent: silently })
  }
})
