PageApp.Ent.FeedModel = Backbone.Model.extend({
  defaults: {
    isVideoVisible: false,
    isAudioOnly: false
  },
  initialize: function () {
  },
  extract: function (models, silently) {
    var content = myApp.ent.auction.get('feedModel')
    if (models && models.feedModel) {
      content = models.feedModel
    }
    this.set(content, { silent: silently })
  }
})
