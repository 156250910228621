PageApp.Controllers.WebcastItemListRouter = function (config) {
  var ent = {}
  var views = {}
  var actions = {
    placeMaxBid: myApp.utils.ajax.getApiEndpoint('webcast2/placeMaxBid'),
    retrieveItemDetails: myApp.utils.ajax.getApiEndpoint('webcast2/item')
  }
  function refreshSellingClosedList (tab) {
    switch (tab) {
      case 'selling':
        $('.w2-sell-item-inplay').show()
        $('.w2-sell-item-selling').show()
        $('.w2-sell-item-closed').hide()
        break

      case 'closed':
        $('.w2-sell-item-inplay').hide()
        $('.w2-sell-item-selling').hide()
        $('.w2-sell-item-closed').show()
    }
  }
  function initializeViews (options) {
    views.itemsLayoutView = new PageApp.Views.ItemsLayoutView()
    views.sellingItemsView = new PageApp.Views.SellingItemsView({ viewData: myApp.ent.stateData, collection: ent.sellingItems })
  }
  function display (region) {
    region.show(views.itemsLayoutView)
    views.itemsLayoutView.sellingRegion.show(views.sellingItemsView)
  }
  function initializeModels (models) {
    var requireRerender
    if (!models) return
    if (models.sellingModel) {
      if (!ent.sellingItems) ent.sellingItems = new PageApp.Ent.SellingItems()
      for (var key in models.sellingModel.liveItems) {
        var sellingItem = models.sellingModel.liveItems[key]
        sellingItem.userBiddingData = myApp.ent.inPlay.getUserBiddingData(sellingItem)
      }
      ent.sellingItems.set(models.sellingModel.liveItems)

      if (!ent.pageInfo) ent.pageInfo = new PageApp.Ent.PageInfo()
      ent.pageInfo.set(models.sellingModel.paginationModel)
    }
    if (models.saleInfoModel) {
      if (!myApp.ent.saleInfo) myApp.ent.saleInfo = new PageApp.Ent.BasicEntity()
      myApp.ent.saleInfo.set(models.saleInfoModel)
    }
    if (models.choicesModel) {
      if (!ent.choices) ent.choices = new PageApp.Ent.Choices()
      ent.choices.set(models.choicesModel)
    }
    // Note: The sellingItemModel clause needs to come before the inPlayModel clause because when a 'pass and next' is performed
    // within the admin console, models contains both. If sellingItemModel is not processed first then the inPlayModel clause sees
    // that there are two inPlay items and takes unnecessary remedial action.
    if (models.sellingItemModel) {
      // It was found that 'ent.sellingItems.add(models.sellingItemModel, {merge: true})' could trigger unnecessary
      // re-rendering of the collection (expensive and slow for large auctions e.g. 1000 items).
      // Appears to be due to a sort being triggered on the collection (within marionette) so we prevent sort
      // unless a new item is being added.

      var existingItem = ent.sellingItems.get(models.sellingItemModel.itemId)
      var requireSort = !existingItem || ((!existingItem.ringDetails || !models.sellingItemModel.ringDetails) && existingItem.ringDetails !== models.sellingItemModel.ringDetails)
      // Merge the item in to the collection or add if not exists
      ent.sellingItems.add(models.sellingItemModel, { merge: true, sort: requireSort })

      // This lack of re-rendering however does cause a problem when we are on the closed tab with only one closed item.
      // If this item is re-offered, passed then re-offered, the empty view is not replaced correctly
      // so we check for this situation and re-render if required.
      requireRerender = $('.w2-empty-view').length > 0 && views.sellingItemsView && !views.sellingItemsView.isEmpty(views.sellingItemsView.collection)
      if (requireRerender) {
        if (console.log) console.log('Perform explicit re-render.')
        views.sellingItemsView.render()
      }
    }
    // Note: The sellingItemModel clause needs to come before the inPlayModel. See comment above.
    if (models.inPlayModel && models.inPlayModel.itemId) {
      // It was found that 'ent.sellingItems.add(models.inPlayModel, {merge: true})' would trigger
      // re-rendering of the collection. Appears to be due to the collection being sorted.
      // We therefore disable sort as this is only necessary when a new item is being added and the inPlay item
      // should never be new.
      ent.sellingItems.add(models.inPlayModel, { merge: true, sort: false })

      // This lack of re-rendering however does cause a problem when we are on the last item which is sold and then the sale is undone.
      // The empty view is not replaced correctly so we check for this situation and re-render if required.
      requireRerender = $('.w2-empty-view').length > 0 && views.sellingItemsView && !views.sellingItemsView.isEmpty(views.sellingItemsView.collection)
      if (requireRerender) {
        if (console.log) console.log('Perform explicit re-render.')
        views.sellingItemsView.render()
      }

      // If the admin moves too quickly through the items, it is possible for the sellingItem models to become corrupted.
      // Items that are no longer inPlay still retain their ringDetails property. This causes multiple inplay items to
      // be rendered over each other on the page with the correct inPlay item often being obscured.
      // If this situation is discovered then correct the item models and re-render.
      var $inPlay = $('.w2-sell-item-inplay')
      if ($inPlay.length >= 2) {
        if (console.log) console.log('More than one inPlay item rendered!')

        $inPlay.each(function () {
          var itemModel = ent.sellingItems.get($(this).data('id'))
          if (itemModel.get('itemId') !== myApp.ent.inPlay.id) {
            itemModel.set('ringDetails', null)
          }
          views.sellingItemsView.children.findByModel(itemModel).render()
        })
      }
    }
    if (models.inPlayImagesModel) {
      if (!ent.inPlayImages) ent.inPlayImages = new PageApp.Ent.InPlayImages(models.inPlayImagesModel)
      ent.inPlayImages.set(models.inPlayImagesModel)
    }
  }
  function notifyModelsChange (response) {
    var itemModel, key, sellingItemModel, groupIdToRemove, sellingItem
    switch (response.code) {
      case myApp.ent.status.getRabbitResponseByName('ADMIN_AUCTION_ACTION_GROUP_CREATE').id:
        // models.sellingItemModel is the new group - remove the group members and re-render...
        sellingItemModel = response.models.sellingItemModel
        for (key in sellingItemModel.groupMembers) {
          ent.sellingItems.remove(sellingItemModel.groupMembers[key].itemId)
        }
        ent.sellingItems.add(sellingItemModel)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_AUCTION_ACTION_GROUP_CREATE_INPLAY').id:
        groupIdToRemove = response.groupIdToRemove
        ent.sellingItems.remove(groupIdToRemove)
        sellingItemModel = response.models.sellingItemModel
        for (key in sellingItemModel.groupMembers) {
          ent.sellingItems.remove(sellingItemModel.groupMembers[key].itemId)
        }
        ent.sellingItems.add(sellingItemModel)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_RING_ACTION_CHOICES_CONFIRMED').id:
        groupIdToRemove = response.groupIdToRemove
        ent.sellingItems.remove(groupIdToRemove)
        sellingItemModel = response.models.sellingItemModel // this is the sold group - (is possible its a group of 1 item)!
        ent.sellingItems.add(sellingItemModel)
        ent.sellingItems.add(response.models.inPlayModel)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_AUCTION_ACTION_UNGROUPED').id:
        var groupId = response.models.sellingModel.xtraId
        var models = response.models.sellingModel.liveItems
        ent.sellingItems.remove(groupId)
        ent.sellingItems.add(models)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        break

      case myApp.ent.status.getRabbitResponseByName('PUBLIC_MAX_BID').id:
        sellingItemModel = response.models.sellingItemModel
        if (sellingItemModel) {
          // The SellingItemView listens to model change events and will re-render automatically.
          ent.sellingItems.get(sellingItemModel.itemId).set(sellingItemModel)
        }
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_ITEM_CHANGE').id:
        var itemModels = response.models.amendedItemsModel.amendedItems
        var newItemsAdded = false
        for (key in itemModels) {
          sellingItem = ent.sellingItems.get(itemModels[key].itemId)
          if (sellingItem) {
            sellingItem.set(itemModels[key], { silent: true })
            views.sellingItemsView.children.findByModel(sellingItem).render()
          } else {
            ent.sellingItems.add(itemModels[key])
            newItemsAdded = true
          }
          if (newItemsAdded) {
            views.sellingItemsView.render()
          }
        }
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_RING_ACTION_SEND_IN').id:
        initializeModels(response.models)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        views.sellingItemsView.collection.sort()
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_AUCTION_ACTION_GROUP_EDITED').id:
        // picks out a child collection view and re-renders it!
        itemModel = (response.models.inPlayModel && response.models.inPlayModel.itemId) ? response.models.inPlayModel : response.models.sellingItemModel
        sellingItem = ent.sellingItems.get(itemModel.itemId)
        sellingItem.set('title', itemModel.title, { silent: false })
        views.sellingItemsView.children.findByModel(sellingItem).render()
        break

      case myApp.ent.status.getRabbitResponseByName('ADMIN_RING_ACTION_SELL_IT').id:
        // picks out a child collection view and re-renders it!
        itemModel = response.models.inPlayModel
        var sellModel = response.models.sellModel
        sellingItem = ent.sellingItems.get(itemModel.itemId)
        if (sellingItem.get('itemId') !== myApp.ent.inPlay.get('itemId')) {
          throw new Error('the sold item is expected to be the in-play item.')
        }
        sellingItem.set(itemModel)
        // views.sellingItemsView.children.findByModel(sellingItem).render();
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')

        // Check the item statuses in the selling list against those supplied in the sellModel and warn the user if
        // things look to have gone awry
        // Time was spent attempting to think of the most efficient way of doing this. Neater solutions that would be perfectly
        // acceptable for small auctions would become increasingly inefficient as the number of items increases.
        var statusesOnServer = sellModel.statuses
        var allOK = true
        if (statusesOnServer) {
          var statusesArray = []
          var si
          var index
          allOK = ent.sellingItems.length === statusesOnServer.length
          if (allOK) {
            for (index = 0; index < ent.sellingItems.length; index++) {
              si = ent.sellingItems.models[index]
              statusesArray.push(si.get('itemId') + '#' + si.get('itemStatusId'))
            }
          }
          statusesArray.sort()

          for (index = 0; index < statusesArray.length && allOK; index++) {
            allOK = statusesArray[index] === statusesOnServer[index]
          }
        }

        if (!allOK) {
          var inconsistencyWarning = myApp.reqres.request('i16:getString', { code: 'WebcastCodes_WC_SELLING_LIST_INCONSISTENT_WITH_SERVER' })
          myApp.vent.trigger('ui:notification', { text: inconsistencyWarning, sticky: false, time: 15000, level: 'warning' })
        }
        break

      default:
        initializeModels(response.models)
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
    }
  }

  function initEvents () {
    myApp.vent.on('webcast:itemlist:display', function (config) {
      initializeModels(config.models)
      initializeViews(config.models)
      myApp.ent.stateData = new PageApp.Ent.StateData({ currentTab: 'selling' })
      display(config.region)
      // Trigger the event to add padding to the top of the first selling item so that it appears beneath the in-play item
      myApp.vent.trigger('items:notifyRequireSellingItemReformat')
      ent.sellingItems.loadRemainingItems()
    }, this)
    myApp.vent.on('webcast:itemlist:model:update', function (response) {
      notifyModelsChange(response)
    }, this)

    myApp.vent.on('notifyTabChange', function (tab) {
      // See whether we are transitioning from an empty tab to a populated one or vice versa to determine whether we need to re-render

      var isEmpty = views.sellingItemsView.isEmpty(views.sellingItemsView.collection)
      myApp.ent.stateData.set('currentTab', tab)
      var rerenderRequired = (isEmpty !== (views.sellingItemsView.isEmpty(views.sellingItemsView.collection)))

      if (tab === 'saleInfo') {
        throw new Error('Should not be here - handled by a modal now.')
      } else {
        // Don't systematically perform a complete re-render of the selling items (using views.sellingItemsView.render) as it's too in-efficient
        // and slow for large numbers of items. Only perform a re-render if we are transitioning from an empty view (i.e. noClosed or noSelling).
        // Instead, if circumstances allow, simply set the css display property as appropriate which is effectively all that the re-render does anyway (in this instance)

        if (rerenderRequired) {
          // This will cause the appropriate emptyView to render or for an emptyView to be replaced with the SellingItems as appropriate
          views.sellingItemsView.render()
        } else {
          refreshSellingClosedList(tab)
        }
        myApp.vent.trigger('items:notifyRequireSellingItemReformat')
      }
    }, this)

    // Item Details tab changer
    myApp.vent.on('notifyTabChangeItemDetails', function (tab) {
      myApp.ent.stateData.set('currentTab', tab)
    }, this)

    myApp.vent.on('items:requireRefreshSellingClosedList', function (tab) {
      refreshSellingClosedList(tab)
    }, this)

    /*
  * Ideally this code to add padding to the first selling item would be triggered by using 'childEvents' on SellingItemsView which
  * would be triggered by a render event on an item in the selling list However it was
  * found that the render events were not fired (or did not bubble up) as expected and so
  * we trigger an event manually. This is possibly due to us having overridden 'render'
  * on SellingItemView. Possibly re-assess should render (which is quite complex) ever be refactored.
  */
    myApp.vent.on('items:notifyRequireSellingItemReformat', function () {
      /*
    * Note: this too is a hack. It appears that the isEmpty check is not performed
    * in all cases when selling items are rendered and as such, in some cases, the
    * empty view is not rendered when it should be, so force a check here and
    * render the collection if empty (which ultimately will cause a NoXXXItems view
    * to be  rendered).
    */
      try {
        if (views.sellingItemsView.isEmpty(views.sellingItemsView.collection)) {
          if (console.log) console.log('re-rendering selling items...')
          views.sellingItemsView.render()
        }
      } catch (err) {
        if (views && views.sellingItemsView) {
          views.sellingItemsView.render()
        }
      }

      var $sellingItems = $('.w2-sell-item-selling')
      $sellingItems.removeClass('w2-first-selling')
      if ($('.w2-sell-item-inplay').length) {
        $sellingItems.first().addClass('w2-first-selling')
      }
    }, this)
    myApp.vent.on('items:placeMaxBid', function (options) {
      var amount = options.amount
      var itemId = options.itemId
      if (!amount || amount.length === 0) {
        return
      }
      var sellingItem = ent.sellingItems.get(itemId)
      var currentBidAmount = sellingItem.attributes.amountHighestBid
      var maxBidAmount = sellingItem.attributes.maxBidAmount

      if (amount <= maxBidAmount) {
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_MAXBID_LOWER_THAN_CURRENT_MAXBID'), level: 'warning' })
      } else if (amount <= currentBidAmount) {
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_MAXBID_LOWER_THAN_CURRENT_HAMMER'), level: 'warning' })
      } else {
        var params = [myApp.ent.auction.get('auctionId'), itemId, myApp.ent.userRegistration.attributes.registrationId, amount]
        myApp.utils.ajax.post(params, actions.placeMaxBid, _.bind(function (response) {
          if (response.worked) {
            var models = myApp.request('reqres:webcast:models', response.models)
            myApp.vent.trigger('webcast:itemlist:model:update', { models: models, code: response.actionCode })
            myApp.vent.trigger('ui:notification', { text: response.message, level: 'success' })
          } else {
            myApp.vent.trigger('ui:notification', { text: response.message, level: 'warning' })
          }
          myApp.vent.trigger('items:notifyRequireSellingItemReformat')
        }, this))
      }
    }, this)
    myApp.vent.on('items:itemDetailsRequest', function (options) {
      var params = [options.itemId]
      myApp.utils.ajax.post(params, actions.retrieveItemDetails, _.bind(function (response) {
        if (response.worked) {
          var view = new PageApp.Views.ItemDetailsView({ model: response.models.sellingItemWithDetailsModel, modal: true })
          myApp.myModalRegion.show(new PageApp.Views.ModalLayout({ view: view, modal: true }))
          this.modalInProgress = true
        } else {
          myApp.vent.trigger('ui:notification', { text: response.message, level: 'warning' })
        }
      }, this))
    }, this)
  }
  return {
    initialize: function (models) {
      myApp.vent.off('webcast:itemlist:model:update')
      myApp.vent.off('items:placeMaxBid')
      myApp.vent.off('items:notifyRequireSellingItemReformat')
      myApp.vent.off('items:itemDetailsRequest')
      myApp.vent.off('items:requireRefreshSellingClosedList')
      myApp.vent.off('notifyTabChange')
      initEvents()
    }
  }
}
