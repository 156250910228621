PageApp.Controllers.CalendarSidebarRouter = function () {
  function initEvents () {
    myApp.vent.on('calendar:sidebar:display', function (models) {
      var model = new PageApp.Ent.BasicEntity({
        title: 'FINISHING SOON!',
        hasFeatures: _.size(models.HomePageModel.upcomingModel.upcomingFeaturedAuctions) > 0,
        hasAuctions: _.size(models.HomePageModel.upcomingModel.upcomingAuctions) > 0,
        featuredAuctionsTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FEATURED_AUCTIONS'),
        auctionsTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_AUCTIONS'),
        upcomingAuctions: models.HomePageModel.upcomingModel.upcomingAuctions,
        upcomingFeaturedAuctions: models.HomePageModel.upcomingModel.upcomingFeaturedAuctions,
        contextPath: myApp.ent.config.get('contextPath')
      })
      myApp.calendarSidebarRegion.on('show', function (view) {
        myApp.vent.trigger('application:rendered', { region: myApp.calendarSidebarRegion })
      })

      myApp.calendarSidebarRegion.show(new PageApp.Views.CalendarSidebarView({ model: model }))
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
