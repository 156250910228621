PageApp.Views.ExchangeRateView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.exchangeRate,
  className: 'rates col-md-6 col-xs-4 text-center',
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  serializeData: function () {
    var currentBid = myApp.ent.inPlay.getCurrentBidAmount() / 100
    var exchange = this.model.attributes
    var amount = (currentBid * exchange.rate * 100) / 100
    amount = Math.round(amount)
    var content = {
      symbol: exchange.currencySymbol,
      amount: myApp.utils.types.toFormattedNumber(amount),
      code: exchange.currencyCode,
      rate: exchange.rate
    }
    return content
  }
})
