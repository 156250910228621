PageApp.Ent.MakeNumericalChoiceModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (silently) {
    var buttonLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CONFIRM_TEXT')
    var highBidLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_HIGHEST_BID_TEXT')
    var numbChosen = 0

    var message = ''
    var units = 0
    var max = _.size(myApp.ent.inPlay.get('groupMembers'))
    var min = myApp.ent.inPlay.get('minimum')
    try {
      numbChosen = myApp.utils.types.toNumber(this.ui.numberRequired.val())
      for (var i = 0; i < numbChosen; i++) {
        units += myApp.ent.inPlay.get('groupMembers')[i].numberOfUnits
      }
      var value = 0
      if (myApp.ent.inPlay.get('biddingTypeEnumId') === 2) { // per item
        value = units * myApp.ent.inPlay.getCurrentBidAmount()
      } else if (myApp.ent.inPlay.get('biddingTypeEnumId') === 3) { // per lot
        value = numbChosen * myApp.ent.inPlay.getCurrentBidAmount()
      }
      if (numbChosen > 0) {
        message = numbChosen + ' = ' + myApp.ent.inPlay.getCurrencySymbol() + ' ' + value / 100
      }
    } catch (ex) {
      message = myApp.reqres.request('i16:getString', { code: 'WebcastCodes_WC_NUMERIC_INSTRUCTION', substitutions: [min, max] })
    }

    var content = {
      minimum: min,
      numbChosen: numbChosen,
      groupMembers: max,
      placeHolderMessage: message,
      buttonLabel: buttonLabel,
      currentBidMessage: highBidLabel,
      currentFormatted: myApp.ent.inPlay.attributes.ringDetails.currentBid.amountFormatted

    }
    this.set(content, { silent: silently })
  }
})
