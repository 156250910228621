PageApp.Views.FiltersView = Backbone.Marionette.ItemView.extend({
  getTemplate: function () {
    return hbs.templates.filters
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-layout': 'handleToggleAuctionLayout',
    'click .x-type': 'handleToggleAuctionType',
    'click .x-country': 'handleToggleCountry'
  },
  serializeData: function () {
    var countryPlaceHolder = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_COUNTRY')
    this.model.set('currentCountry', countryPlaceHolder)
    var showMarketplace = this.model.get('countMarketplaceItems') > 0
    var showCountries = _.size(this.model.get('countries')) > 1
    var showAuctions = this.model.getAuctionCount() > 0 && showMarketplace
    if (showMarketplace && this.model.getAuctionCount() <= 0) {
      myApp.ent.user.setPreference('homecalType', { value: 'marketplace' })
    }
    var type = myApp.ent.user.getPreference('homecalType', { value: 'auctions' })
    var showLayoutToggle = this.model.getAuctionCount() > 0 && (type === 'auctions')

    var showFilters = this.model.getAuctionCount() > 0 || showMarketplace

    var isEmpty = this.model.getAuctionCount() <= 0 && !showMarketplace

    var layout = myApp.ent.user.getPreference('homecalLayout', { value: 'gallery' })

    return {
      isEmpty: isEmpty,
      showFilters: showFilters,
      emptyView: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMECAL_EMPTY_VIEW'),
      listView: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_LIST_VIEW'),
      galleryView: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_GALLERY_VIEW'),
      allCountriesText: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_ALL_COUNTRIES'),
      auctionsTab: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_TAB'),
      marketplaceTab: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MARKETPLACE_TAB'),
      countryPlaceHolder: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_COUNTRY'),
      currentCountry: this.model.get('currentCountry'),
      showCountries: showCountries,
      countries: this.model.get('countries'),
      showMarketplace: showMarketplace,
      showAuctions: showAuctions,
      showLayoutToggle: showLayoutToggle,
      isAuctionTypeActive: (type === 'auctions'),
      isMarketplaceTypeActive: (type === 'marketplace'),
      isGridLayoutActive: (type === 'auctions' && layout === 'gallery'),
      isListLayoutActive: (type === 'auctions' && layout === 'list')
    }
  },

  handleToggleCountry: function (e) {
    e.preventDefault()
    var country = $(e.currentTarget).data('country')
    if (country === 'all') {
      this.model.set('currentCountry', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UPCOMING_AUCTIONS_ALL_COUNTRIES'))
    } else {
      this.model.set('currentCountry', country)
    }
    myApp.vent.trigger('homecal:toggleCountry', { country: country })
  },
  handleToggleAuctionLayout: function (e) {
    e.preventDefault()
    var layout = $(e.currentTarget).data('layout')
    myApp.ent.user.setPreference('homecalLayout', layout)
    myApp.vent.trigger('homecal:toggleLayout')
    return false
  },
  handleToggleAuctionType: function (e) {
    e.preventDefault()
    var type = $(e.currentTarget).data('type')
    myApp.ent.user.setPreference('homecalType', type)
    myApp.vent.trigger('homecal:toggleType')
    return false
  }
})
