PageApp.Ent.LotTitleModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (inPlay, silently) {
    if (!inPlay) return
    var content = {
      title: inPlay.attributes.title,
      lotNumber: inPlay.attributes.lotNumber,
      lot: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LOT_TEXT')
    }
    this.set(content, { silent: silently })
  }
})
