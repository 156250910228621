PageApp.Views.WebcastLayout = Backbone.Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.webcastLayout
  },
  initialise: function (options) {
    this.options = options
  },
  regions: {
    webcastRegion: '#webcastRegion',
    messageRegion: '#messageRegion',
    w2ItemsRegion: '#w2ItemsRegion',
    titleRegion: '#titleRegion',
    w2VideoRegion: '#w2VideoRegion'
  }
})
