PageApp.Ent.ChangePassword = Backbone.Model.extend({
  defaults: {
    '@class': 'com.bidlogix.scaffold.models.account.ChangePasswordModel'
  },
  validate: function () {
    var errors = new PageApp.Ent.Errors()
    var error = null
    var token = this.get('token')
    if (!token || token.length === 0) {
      error = {
        field: 'token',
        message: 'Invalid Token'
      }
      errors.add(error)
    }
    if (this.get('password').length < 5) {
      error = {
        field: 'password',
        message: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_Password')
      }
      errors.add(error)
    } else if (this.get('password') !== this.get('passwordConfirm')) {
      error = {
        field: 'passwordConfirm',
        message: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CreateAccount_RepeatPassword')
      }
      errors.add(error)
    }

    if (errors.length === 0) {
      return false
    } else {
      return errors
    }
  }
})
