PageApp.Views.ChangePasswordSuccessView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.changePasswordSuccess,
  serializeData: function () {
    var loginUrl = myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl ? myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl : myApp.ent.config.attributes.contextPath + '/login'
    return {
      JspPublicCodes_JSP_PASSWORD_CHANGED: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_CHANGED'),
      JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL'),
      JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL_LOGIN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_CHANGE_SUCCESSFUL_LOGIN'),
      contextPath: myApp.ent.config.attributes.contextPath,
      loginUrl: loginUrl
    }
  }
})
