PageApp.Views.NoSellingItemView = Backbone.Marionette.ItemView.extend({

  template: hbs.templates.noSellingItemView,

  serializeData: function () {
    var content = {
      noSellingItemsMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_ALL_CLOSED')
    }
    return content
  }

})
