PageApp.Views.ImagesView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.imagesView,
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  ui: {
    openLightbox: '.x-open-lightbox'
  },
  events: {
    'click .x-open-lightbox': 'handleOpenLightbox',
    'click .x-toggle-preview': 'handleTogglePreview'
  },
  handleOpenLightbox: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    $target.ekkoLightbox({
      onShown: function () {
        var lightbox = this
        myApp.vent.on('lightbox:close', function () {
          lightbox.close()
        })
      }
    })
    return false
  },
  handleTogglePreview: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    var url = $target.data('preview')
    this.$el.find('.w2-inplay-image-main').attr('src', url)
    return false
  }
})
