PageApp.Views.AppMessageView = Backbone.Marionette.ItemView.extend({

  getTemplate: function () {
    return hbs.templates.appMessage
  },

  modelEvents: {
    'change': 'render'
  },

  events: {
    'click .x-close-auction-message': 'handleCloseMessage',
    'click #auctionMessage .close': 'handleMessageClose'
  },

  ui: {
    messageContainer: '.w2-message-auction-level'
  },

  onRender: function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 1) {
        $('.w2-message-auction-level').addClass('w2-message-auction-level-fixed')
      } else {
        $('.w2-message-auction-level').removeClass('w2-message-auction-level-fixed')
      }
    })
  },

  serializeData: function () {
    var messageText
    var messageModel = this.model.get('messageModel')
    if (messageModel) {
      if (messageModel.messageTarget === 'WINNING_BIDDER') { // specific bidder
        if (myApp.ent.status.isUserWinningOrWon()) {
          messageText = messageModel.messageText
        }
      } else {
        messageText = messageModel.messageText
      }
    }

    var messageTextDisable = (messageText) && messageText.trim().length > 0 ? '' : 'hidden'

    return {
      messageText: messageText,
      messageTextDisable: messageTextDisable
    }
  },
  handleMessageClose: function () {
    $('#auctionMessage').hide()
  }

})
