PageApp.Ent.SellingItems = Backbone.Collection.extend({
  model: PageApp.Ent.SellingItem,
  initialize: function () {
    this.on('change:ringDetails change:index', this.sort, this)
  },
  // sort so that any in the ring are moved to the top
  comparator: function (a, b) {
    var isSellingA = a.attributes.itemStatusId === 4 || (a.inPlay && myApp.ent.inPlay.isWinningOrWon() && myApp.ent.auction.inPlayEndedWaitingOrChosen())
    var isSellingB = b.attributes.itemStatusId === 4 || (b.inPlay && myApp.ent.inPlay.isWinningOrWon() && myApp.ent.auction.inPlayEndedWaitingOrChosen())
    if (a.attributes.ringDetails && isSellingA) {
      if (b.attributes.ringDetails) {
        return 0
      }
      return -1
    } else {
      if (b.attributes.ringDetails && isSellingB) {
        return 1
      }
      if (a.attributes.index < b.attributes.index) {
        return -1
      }
      return 1
    }
  },

  actions: {
    sellingItemsRemainder: function () {
      return myApp.utils.ajax.getApiEndpoint('webcast2/sellingItemsRemainder')
    }
  },

  totalItemCount: function () {
    var total = 0
    for (var key in this.models) {
      var itemOrGroup = this.models[key]
      var groupMembers = itemOrGroup.attributes.groupMembers
      total += (groupMembers ? groupMembers.length : 1)
    }
    return total
  },

  loadRemainingItems: function () {
    // if the initial selling items provided within models is the full initial set then there may be more so retrieve them
    if (this.totalItemCount() >= myApp.ent.config.get('itemsPageSize')) {
      var params = [ myApp.ent.auction.get('auctionId') ]
      // Using _.bind below in order that we can refer to 'this' in doLoadRemainingItemsSuccess and we will be referring to the correct 'this'.
      myApp.utils.ajax.get(params, this.actions.sellingItemsRemainder(), _.bind(this.doLoadRemainingItemsSuccess, this))
    }
  },

  doLoadRemainingItemsSuccess: function (response) {
    if (response.worked && response.models.sellingModelRemainder) {
      this.add(response.models.sellingModelRemainder.liveItems)
      myApp.vent.trigger('items:notifyRequireSellingItemReformat')
    }
  }

})
