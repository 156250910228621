PageApp.Views.SellingItemView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.sellingItemView,
  templateClosed: hbs.templates.closedItemView,
  templateGroupMember: hbs.templates.groupedItemView,
  className: 'list-group-item',
  getTemplate: function () {
    return hbs.templates.sellingItemView
  },
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click .x-group-display': 'handleToggleGroup',
    'click .x-group-member-select': 'handleChooseMember',
    'click .x-show-item-detail': 'handleItemDetail',
    'click .x-close-slide-menu': 'handleCloseSlideMenu'
  },
  modelEvents: {
    'change': 'render'
  },
  render: function () {
    var item = this.model.attributes
    if (!item.itemStatusId) return
    var options = { groupButtonHidden: true, displayItem: '' }
    var showAsOpen = false
    if (item.group) {
      options.groupButtonHidden = false
      options.groupButtonLabel = (myApp.ent.stateData.isGroupShowing(item.itemId)) ? 'Hide Group' : 'Show Group'
      options.groupDisplay = (myApp.ent.stateData.isGroupShowing(item.itemId))
      if (myApp.ent.inPlay &&
        myApp.ent.inPlay.get('itemId') === item.itemId &&
        myApp.ent.inPlay.isWon() &&
        myApp.ent.auction.inPlayEndedWaitingOrChosen()
      ) {
        options.groupDisplay = true
        options.groupButtonLabel = 'Hide Group'
      }
      if (item.itemId === myApp.ent.inPlay.get('itemId') && myApp.ent.inPlay.isWinningOrWon() && myApp.ent.auction.inPlayEndedWaitingOrChosen()) {
        myApp.ent.stateData.addGroupShowing(item.itemId)
        showAsOpen = true
      }
    }

    if (item.itemStatusId === 4 || showAsOpen) {
      options.displayItem = (myApp.ent.stateData.get('currentTab') === 'closed') ? 'hidden' : ''
      this.renderSelling(item, options)
    } else {
      if (item.inPlay && myApp.ent.inPlay.isWinningOrWon() && myApp.ent.auction.inPlayEndedWaitingOrChosen()) {
        options.displayItem = (myApp.ent.stateData.get('currentTab') === 'closed') ? 'hidden' : ''
        this.renderSelling(item, options)
      } else {
        options.displayItem = (myApp.ent.stateData.get('currentTab') === 'selling') ? 'hidden' : ''
        this.renderClosed(item, options)
      }
    }
    if (options.displayItem === 'hidden') {
      this.$el.hide()
    } else {
      this.$el.show()
    }
    // Add a class to the item's div added by marionette for future use.
    this.$el.addClass('x-lot-outer-' + item.lotNumber)
    return this
  },
  renderClosed: function (item, options) {
    var showWinningBidAmount = myApp.ent.webcast2Settings.get('showWinningBidAmount') && item.itemStatusId === 6
    var userBiddingData = item.userBiddingData
    var currentBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CURRENT_BID_CLOSED_TEXT') + ':'
    if (item.itemStatusId === 9) {
      currentBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_PASSED_TEXT')
    } else if (!showWinningBidAmount) {
      currentBidText = ''
    }
    var lotLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LOT_TEXT')
    var hideGroupUnits
    var itemNumberOfUnits

    if (item.group || item.numberOfUnits < 2) {
      hideGroupUnits = 'hidden'
    } else {
      itemNumberOfUnits = item.numberOfUnits
    }

    var content = {
      id: item.itemId,
      imageThumbSrc: (item.imageSrc) ? item.imageSrc : '?',
      lotNumber: item.lotNumber,
      lotCurrentBid: (showWinningBidAmount) ? item.amountHighestBidFormatted : '',
      lotTitle: item.title,
      lotSummary: item.summary,
      displayItem: options.displayItem,
      groupDisplay: options.groupDisplay,
      groupButtonHidden: options.groupButtonHidden,
      groupButtonLabel: options.groupButtonLabel,
      currentBidText: currentBidText,
      lot: lotLabel,
      showDetailsClass: (item.group ? 'x-group-display' : 'x-show-item-detail'),
      wonLostMessage: (userBiddingData) ? userBiddingData.wonLostMessage : '',
      itemNumberOfUnits: itemNumberOfUnits,
      hideGroupUnits: hideGroupUnits
    }
    this.$el.html(this.templateClosed(content))
    this.$el.addClass('w2-sell-item-closed').removeClass('w2-sell-item-inplay').removeClass('active')
    this.renderGroupMembers(item, false)
    return this
  },
  renderSelling: function (item, options) {
    var toggleSellTag
    var hasBidActions = false
    var buttonText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CONFIRM_TEXT')
    var placeHolderMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_ENTER_MAX_BID_TEXT')
    var currentBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CURRENT_BID_TEXT') + ':'
    var currentMaxBidText = ''
    var currentMaxBidAmount = ''
    var itemNumberOfUnits
    var hideGroupUnits
    var activeClass = 'w2-sell-item-inplay active'
    var inactiveClass = 'w2-sell-item-selling'

    var userBiddingData = item.userBiddingData
    if (userBiddingData && userBiddingData.maxBidAmountFormatted) {
      currentMaxBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CURRENT_MAX_BID_TEXT') + ':'
      currentMaxBidAmount = userBiddingData.maxBidAmountFormatted
    } else if (item.maxBidHistory) {
      for (var key in item.maxBidHistory) {
        var maxBid = item.maxBidHistory[key]
        if (myApp.ent.user.get('id') === maxBid.userId) {
          currentMaxBidText = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CURRENT_MAX_BID_TEXT') + ':'
          currentMaxBidAmount = maxBid.amountFormatted
        }
      }
    }
    if (item.ringDetails) {
      toggleSellTag = 'inline-block'
    } else {
      toggleSellTag = 'none'
      hasBidActions = true
    }
    var imageSrc = item.imageSrc
    if (item.group) {
      hideGroupUnits = 'hidden'
      try {
        imageSrc = item.groupMembers[0].imageSrc
      } catch (e) { }
    }
    if (item.numberOfUnits < 2) {
      hideGroupUnits = 'hidden'
    } else {
      itemNumberOfUnits = item.numberOfUnits
    }

    if (!myApp.ent.user.isLoggedIn()) {
      hasBidActions = false
    }

    var content = {
      id: item.itemId,
      imageThumbSrc: (imageSrc) || '?',
      lotNumber: item.lotNumber,
      currentBidText: currentBidText,
      lotCurrentBid: item.amountHighestBidFormatted,
      lotTitle: item.title,
      lotSummary: item.summary,
      toggleSellTag: toggleSellTag,
      lotSellingStatus: item.itemStatusId,
      hasBidActions: hasBidActions,
      buttonText: buttonText,
      placeHolderMessage: placeHolderMessage,
      displayItem: options.displayItem,
      currentMaxBidText: currentMaxBidText,
      currentMaxBidAmount: currentMaxBidAmount,
      groupDisplay: options.groupDisplay,
      groupButtonLabel: options.groupButtonLabel,
      groupButtonHidden: options.groupButtonHidden,
      itemNumberOfUnits: itemNumberOfUnits,
      hideGroupUnits: hideGroupUnits,
      lot: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LOT_TEXT'),
      showDetailsClass: (item.group ? 'x-group-display' : 'x-show-item-detail')

    }
    this.$el.html(this.template(content))
    this.$el.removeClass('w2-sell-item-closed')
    if (item.ringDetails) {
      this.$el.addClass(activeClass).removeClass(inactiveClass)
    } else {
      this.$el.addClass(inactiveClass).removeClass(activeClass)
    }
    var choice = myApp.ent.auction.get('inPlayEndedWaitingChoice') && item.choiceEnumId === 2
    this.renderGroupMembers(item, choice)

    return this
  },
  renderGroupMembers: function (item, choice) {
    if (item.group) {
      var $container = this.$el.find('#groupClosedOfItems_' + item.itemId)

      for (var key in item.groupMembers) {
        var groupMember = item.groupMembers[key]
        var itemNumberOfUnits = groupMember.numberOfUnits

        var content = {
          groupMemberId: groupMember.itemId,
          imageThumbSrc: (groupMember.imageSrc) ? groupMember.imageSrc : '?',
          lotNumber: groupMember.lotNumber,
          lotTitle: groupMember.title,
          lotSummary: groupMember.summary,
          groupMemberActive: (choice),
          itemNumberOfUnits: itemNumberOfUnits,
          lot: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LOT_TEXT')

        }
        $container.append(this.templateGroupMember(content))
      }
    }
  },
  handleToggleGroup: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    var itemId = this.model.get('itemId')
    if ($target.data('id') !== itemId) throw new Error('this isn\'t the view clicked on!')
    if (myApp.ent.stateData.isGroupShowing(itemId)) {
      myApp.ent.stateData.removeGroupShowing(itemId)
      this.$el.removeClass('w2-sell-item-group-bg')
    } else {
      myApp.ent.stateData.addGroupShowing(itemId)
      this.$el.addClass('w2-sell-item-group-bg')
    }
    this.render()
    myApp.vent.trigger('items:notifyRequireSellingItemReformat')
  },

  handleItemDetail: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    var itemId = $target.data('id')
    myApp.vent.trigger('items:itemDetailsRequest', { itemId: itemId })
  },

  handleChooseMember: function (e) {
    var $target = $(e.currentTarget)
    var itemId = $target.data('id')
    var tickAll = $target.is(':checked')

    if (e.shiftKey) {
      var $potentialItems = this.$el.find('.x-group-member-select')
      var started = false
      var finished = false

      for (var i = ($potentialItems.length - 1); i >= 0 && !finished; i--) {
        var choiceItem = $potentialItems.get(i)
        if (choiceItem.dataset.id === itemId) {
          started = true
        } else if (started) {
          if (choiceItem.checked !== tickAll) {
            choiceItem.checked = tickAll
          } else {
            finished = true
          }
        }
      }
    }

    // Note: can't reuse $potentialItems so find again.
    this.$el.find('.x-group-member-select').each(function () {
      var member = myApp.ent.inPlay.get('groupMembers').find(member => member.itemId === $(this).data('id'))
      member.chosen = $(this).prop('checked')
    }, this)

    myApp.vent.trigger('webcast:inplay:choice:changed', { amount: myApp.ent.stateData })
  }

})
