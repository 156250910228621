PageApp.Views.VideoStreamView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.webrtcView,
  initialize: function (options) {
    this.options = options
    myApp.vent.on('webcast:video:view:hide', function () {
      $(this.ui.feedContainer).addClass('hidden')
      myApp.vent.trigger('webcast:video:hide')
    }, this)
    myApp.vent.on('webcast:video:view:show', function () {
      $(this.ui.feedContainer).removeClass('hidden')
      this.$el.find('#tokvid').remove()
      this.$el.find('.w2-feed-box').append('<div id="tokvid"></div>')
      myApp.vent.trigger('webcast:video:show')
    }, this)
  },
  events: {
    'click .OT_mute': 'handleMute'
  },
  ui: {
    feedContainer: '.w2-feed-box'
  },
  handleMute: function (e) {
    e.preventDefault()
  }
})
