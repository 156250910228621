PageApp.Views.ItemsLayoutView = Backbone.Marionette.LayoutView.extend({
  getTemplate: function () {
    return hbs.templates.itemsLayout
  },
  regions: {
    tabRegion: '#tabView',
    sellingRegion: '#sellingView',
    saleInfoRegion: '#saleInfoView',
    itemDetailsRegion: '#itemDetailsView'
  },
  initialize: function () {
  },
  ui: {
    gotoLotListItem: '.w2-gotolot',
    gotoLotInput: '.w2-gotolot-input'
  },
  events: {
    'click .x-change-tab': 'handleTabChange',
    'keyup .w2-gotolot-input': 'handleGotoLot',
    'click .x-saleinfo-modal': 'handleSaleInfoShow'
  },

  serializeData: function () {
    return {
      gotoLotText: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_GOTO_LOT_TEXT')
    }
  },

  handleTabChange: function (e) {
    var $target = $(e.currentTarget)
    var action = $target.data('action')
    myApp.vent.trigger('notifyTabChange', action)
    if (action === 'saleInfo') {
      this.ui.gotoLotListItem.hide()
    } else {
      this.ui.gotoLotListItem.show()
    }
  },

  handleGotoLot: function (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      var lotNumber = this.ui.gotoLotInput.val().toUpperCase()
      var target = $('.x-lot-' + lotNumber + ':visible')

      if (target.length > 0) {
        var newScrollTop = target.offset().top
        $('html, body').animate({ scrollTop: newScrollTop }, 1000)
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_SCROLLED_TO_LOT') + ' ' + lotNumber + '.', time: 1000 })
      } else {
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_UNABLE_TO_FIND_LOT') + ' ' + lotNumber + '.', time: 1000 })
      }
    }
    return false
  },
  handleSaleInfoShow: function (e) {
    e.preventDefault()
    myApp.ent.saleInfo.set('source', 'webcast')
    myApp.vent.trigger('saleinfo:display', { saleInfoModel: myApp.ent.saleInfo })
    return false
  }
})
