PageApp.Ent.ChoicesModel = Backbone.Model.extend({
  initialize: function () {
    this.set('choices', [])
  },
  extract: function (choices, silently) {
    if (!choices) return
    var content = choices
    this.set(content, { silent: silently })
  },
  reset: function (type) {
    this.set('choices', [])
  },
  hasAny: function (itemId) {
    return _.size(this.get('choices')) > 0
  },
  add: function (itemId) {
    if (_.indexOf(this.get('choices'), itemId) === -1) this.get('choices').push(itemId)
  },
  remove: function (itemId) {
    var index = _.indexOf(this.get('choices'), itemId)
    if (index > -1) this.get('choices').splice(index, 1)
  }
})
