PageApp.Views.UpcomingGalleryView = Backbone.Marionette.ItemView.extend({
  initialize: function (options) {
    this.options = options
  },
  template: hbs.templates.upcomingGallery,
  appendRegistrantStatusToAuction: function (auction) {
    return Object.assign({}, auction, {
      registrantStatus: this.model.registeredFor[auction.auctionId]
    })
  },
  appendStartTimeStringToAuction: function (auction) {
    if (auction.auctionStartTime && auction.endsOrStarts !== 'Started') {
      let startString
      if (auction.auctionStartTime < myApp.ent.config.getNormalisedTimeMillis()) {
        startString = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_STARTED')
      } else {
        startString = auction.auctionStartTimeString
      }
      return Object.assign({}, auction, {
        startTimeString: startString
      })
    }
    return auction
  },
  serializeData: function () {
    var upcomingAuctions = this.filterByCountry(this.model.upcomingAuctions, this.model.country)
      .map(this.appendRegistrantStatusToAuction.bind(this))
      .map(this.appendStartTimeStringToAuction.bind(this))
    var upcomingFeaturedAuctions = this.filterByCountry(this.model.upcomingFeaturedAuctions, this.model.country)
      .map(this.appendRegistrantStatusToAuction.bind(this))
      .map(this.appendStartTimeStringToAuction.bind(this))
    var itemMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    var itemsMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')

    for (var key in upcomingAuctions) {
      upcomingAuctions[key].message = (upcomingAuctions[key].itemCount > 1) ? itemsMessage : itemMessage
    }
    for (var key2 in upcomingFeaturedAuctions) {
      upcomingFeaturedAuctions[key2].message = (upcomingFeaturedAuctions[key2].itemCount > 1) ? itemsMessage : itemMessage
    }
    var dims = myApp.ent.layoutHacks.getDimensionsHomeCal()
    dims.operation = myApp.ent.config.get('auctionImageTransformOperation')

    return {
      dims: dims,
      contextPath: myApp.ent.config.get('contextPath'),
      upcomingAuctions: upcomingAuctions,
      upcomingFeaturedAuctions: upcomingFeaturedAuctions,
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE'),
      auctionRegistrantStatusMessage: {
        ISSUE_WITH_DEPOSIT: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_ISSUE_WITH_DEPOSIT'),
        NOT_REGISTERED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_NOT_REGISTERED'),
        PENDING: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_PENDING'),
        APPROVED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_APPROVED'),
        SUSPENDED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_SUSPENDED'),
        AWAITING_DEPOSIT: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_AWAITING_DEPOSIT'),
        INSUFFICIENT_DATA: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_INSUFFICIENT_DATA')
      },
      registeredAuctions: this.model.registeredFor,
      catalogueAuctionMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_AUCTION'),
      maxWidth: dims.width
    }
  },
  filterByCountry: function (auctions, country) {
    if (!this.model.country || this.model.country === 'all') {
      return auctions
    }
    var filteredAuctions = []
    for (var key in auctions) {
      var location = auctions[key].location
      if (location.indexOf(this.model.country) > -1) {
        filteredAuctions.push(auctions[key])
      }
    }
    return filteredAuctions
  }
})
