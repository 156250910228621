PageApp.Views.ForgottenPasswordView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.forgottenPassword,
  initialize: function (options) {
    this.options = options
  },
  ui: {
    email: '.x-email'
  },
  serializeData: function () {
    return {
      JspPublicCodes_JSP_RESET_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_RESET_PASSWORD'),
      JspPublicCodes_JSP_FORGOTTEN_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FORGOTTEN_PASSWORD'),
      JspPublicCodes_JSP_PASSWORD_RESET_INSTRUCTIONS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_RESET_INSTRUCTIONS'),
      JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ADDRESS: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ADDRESS'),
      JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ME: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FORGOTTEN_PASSWORD_EMAIL_ME'),
      email: this.model.get('email'),
      resetPasswordMode: this.model.get('resetPasswordMode'),
      contextPath: myApp.ent.config.get('contextPath')
    }
  },
  events: {
    'click .x-submit': 'handleSubmit',
    'keyup .x-email': 'handleSubmitFromInput'
  },
  onRender: function () {
    if (!this.errors) return
    this.errors.each(function (element) {
      if (element.get('field')) {
        this.ui.email.parent().find('span').html(element.get('message'))
        this.ui.email.parent().addClass('has-error')
      } else {
        var $ge = this.$el.find('#globalError')
        $ge.parent().addClass('has-error')
        $ge.html(element.get('message'))
      }
    }, this)
  },
  handleSubmitFromInput: function (e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      this.handleSubmit(e)
    }
    return false
  },
  handleSubmit: function (e) {
    e.preventDefault()
    this.model.set('email', this.ui.email.val())
    this.errors = this.model.validate()
    if (this.errors) {
      this.render()
      return
    }
    var baseUrl = encodeURIComponent(window.location.href)
    myApp.utils.ajax.postBean(this.model.attributes, myApp.utils.ajax.getApiEndpoint('forgottenPassword') + '?baseUrl=' + baseUrl, function (response) {
      var errors = response.errors
      if (response.failed || (typeof errors !== 'undefined' && errors !== null && errors.length !== 0)) {
        myApp.vent.trigger('ui:notification', { sticky: true, text: errors[0].message, level: 'danger' })
      } else {
        myApp.vent.trigger('account:forgotten:success', response)
      }
    })
    return false
  }
})
