PageApp.Views.AuctioneerStripView = Backbone.Marionette.ItemView.extend({
  initialize: function (options) {
    this.options = options
  },
  template: hbs.templates.auctioneerStrip,
  serializeData: function () {
    var content = {
      contextPath: myApp.ent.config.get('contextPath'),
      auctioneerName: this.model.get('name'),
      auctioneerEmail: this.model.get('email'),
      logo: this.model.get('logo'),
      contactNumber: this.model.get('contactNumber'),
      auctioneerWebsite: this.model.get('website'),
      auctioneerId: this.model.get('auctioneerId'),
      JspPublicCodes_JSP_AUCTIONEER_STRIP_SALEMGTBY: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTIONEER_STRIP_SALEMGTBY'),
      JspPublicCodes_JSP_AUCTIONEER_STRIP_ENQUIRIES: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTIONEER_STRIP_ENQUIRIES'),
      JspPublicCodes_JSP_SHORT_TELEPHONE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_SHORT_TELEPHONE'),
      JspPublicCodes_JSP_EMAIL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_EMAIL'),
      JspPublicCodes_JSP_WEBSITE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_WEBSITE'),
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE')
    }
    return content
  }
})
