PageApp.Views.HomeLayout = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.homePageLayout,
  className: function () { return myApp.ent.config.get('containerClass') },
  regions: {
    bannerRegion: '#bannerView',
    searchRegion: '#homeSearchInputView',
    filtersRegion: '#filtersView',
    upcomingRegion: '#upcomingView'
  }
})
