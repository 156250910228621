PageApp.Views.EmptyView = Backbone.Marionette.ItemView.extend({

  template: hbs.templates.noInPlayItemView,

  serializeData: function () {
    var content = {
      noInPlayItemMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_NO_INPLAY_ITEM')
    }
    return content
  }

})
