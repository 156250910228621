PageApp.Controllers.WebcastInPlayRouter = function (config) {
  var myRegion = ''
  var viewModels = {}
  var layoutModel
  var inPlayLayoutView
  var mouseOverQueue = []
  var maxMouseOverQueueSize = 30
  var lastSentStateWasHovering = false
  var timePeriodWindowLengthMs = 30000
  var actions = {
    placeMaxBid: myApp.utils.ajax.getApiEndpoint('webcast2/placeMaxBid'),
    placeBid: myApp.utils.ajax.getApiEndpoint('webcast2/placeBid'),
    makeChoice: myApp.utils.ajax.getApiEndpoint('webcast2/makeChoice'),
    mouseOverBid: myApp.utils.ajax.getApiEndpoint('webcast2/mouseOverBid')
  }
  function popupAlert (models) {
    var popup = models.popupMessageModel
    if (popup) {
      if (popup.messageTarget === 'WINNING_BIDDER') { // specific bidder
        if (myApp.ent.status.isUserWinningOrWon()) {
          myApp.vent.trigger('ui:notification', {
            text: models.popupMessageModel.messageText, sticky: true
          })
        }
      } else if (popup.messageTarget === 'SPECIFIC_BIDDER') { // specific
        // bidder
        if (popup.recipientUserId === myApp.ent.user.attributes.id) {
          myApp.vent.trigger('ui:notification', {
            text: models.popupMessageModel.messageText, sticky: true
          })
        }
      } else {
        myApp.vent.trigger('ui:notification', {
          text: models.popupMessageModel.messageText, sticky: true
        })
      }
    }
  }
  function initViewModels () {
    if (!viewModels.auctionPausedModel) {
      viewModels.auctionPausedModel = new PageApp.Ent.AuctionPausedModel()
    }
    // if (!viewModels.maxBidModel) {
    //   viewModels.maxBidModel = new PageApp.Ent.MaxBidModel()
    // }
    if (!viewModels.bidModel) {
      viewModels.bidModel = new PageApp.Ent.BidModel()
    }
    if (!viewModels.choicesModel) {
      viewModels.choicesModel = new PageApp.Ent.ChoicesModel()
    }
    if (!viewModels.choiceMadeModel) {
      viewModels.choiceMadeModel = new PageApp.Ent.ChoiceMadeModel()
    }
    if (!viewModels.feedModel) {
      viewModels.feedModel = new PageApp.Ent.FeedModel()
    }
    if (!viewModels.inPlayImagesModel) {
      viewModels.inPlayImagesModel = new PageApp.Ent.InPlayImagesModel()
    }
    if (!viewModels.itemMessageModel) {
      viewModels.itemMessageModel = new PageApp.Ent.ItemMessageModel()
    }
    if (!viewModels.makeChoiceModel) {
      viewModels.makeChoiceModel = new PageApp.Ent.MakeChoiceModel()
    }
    if (!viewModels.makeNumericalChoiceModel) {
      viewModels.makeNumericalChoiceModel = new PageApp.Ent.MakeNumericalChoiceModel()
    }
    if (!viewModels.lotTitleModel) {
      viewModels.lotTitleModel = new PageApp.Ent.LotTitleModel()
    }
    if (!viewModels.messagesModel) {
      viewModels.messagesModel = new PageApp.Ent.MessagesModel()
    }
    if (!viewModels.exchangeRatesModel) {
      viewModels.exchangeRatesModel = new PageApp.Ent.ExchangeRatesModel()
    }
  }
  function setViewModels (models, silently) {
    initViewModels()
    viewModels.auctionPausedModel.extract(models, silently)
    // viewModels.maxBidModel.extract(models, silently)
    viewModels.bidModel.extract(models, silently)
    viewModels.choicesModel.extract(models.choicesModel, silently)
    viewModels.choiceMadeModel.extract(silently)
    viewModels.feedModel.extract(models, silently)
    viewModels.inPlayImagesModel.extract(models.inPlayImagesModel, silently)
    viewModels.itemMessageModel.extract(silently)
    viewModels.makeChoiceModel.extract(silently)
    viewModels.makeNumericalChoiceModel.extract(silently)
    viewModels.lotTitleModel.extract(myApp.ent.inPlay, silently)
    viewModels.messagesModel.extract(myApp.ent.inPlay, silently)
    // for some reason the server returns webcast2ExchangeRatesModel or
    // exchangeRatesModel depending if full page load!
    if (models.webcast2ExchangeRatesModel) {
      viewModels.exchangeRatesModel.extract(models.webcast2ExchangeRatesModel.exchangeRates, silently)
    } else if (models.exchangeRatesModel && models.exchangeRatesModel.exchangeRates) {
      viewModels.exchangeRatesModel.extract(models.exchangeRatesModel.exchangeRates, silently)
    }
    return viewModels
  }
  function notifyModelsChange (models, responseCode, silence) {
    if (!layoutModel) {
      layoutModel = new PageApp.Ent.InPlayLayoutModel()
    }
    try {
      setViewModels(models, silence.views)
    } catch (err) {
      console.log('webcast: no item in play or auction paused..')
      silence.layout = false // force re-render of layout..
    }

    layoutModel.once('change', function (view) {
      var $lightSlider = $('#lightSlider')
      myApp.vent.trigger('webcast:inplay:slider', {
        myel: $lightSlider
      })
    })
    layoutModel.extract(models, responseCode, silence.layout, viewModels.exchangeRatesModel)
    popupAlert(models)
  }
  function initLightSliderEvent () {
    myApp.vent.on('webcast:inplay:slider', function (slider) {
      slider.myel.lightSlider({
        autoWidth: true,
        gallery: true,
        cssEasing: 'ease',
        auto: true,
        loop: true,
        slideMargin: 0,
        mode: 'fade',
        controls: true,
        prevHtml: '',
        nextHtml: '',
        speed: 1000,
        pause: 3000
      })
    }, this)
  }
  function initEvents () {
    myApp.vent.once('webcast:inplay:display', function (config) {
      myRegion = config.region
      notifyModelsChange(config.models, 0, {
        layout: true, views: true
      })
      inPlayLayoutView = new PageApp.Views.InPlayLayoutView({
        model: layoutModel, viewModels: viewModels
      })
      inPlayLayoutView.once('show', function (view) {
        var $lightSlider = $('#lightSlider')
        myApp.vent.trigger('webcast:inplay:slider', {
          myel: $lightSlider
        })
      })
      myRegion.show(inPlayLayoutView)
    }, this)
    myApp.vent.on('webcast:inplay:model:update', function (response) {
      var silence = {
        layout: false, views: true
      }
      notifyModelsChange(response.models, response.code, silence)
    }, this)
    myApp.vent.on('webcast:inplay:choice:changed', function (models) {
      notifyModelsChange(models, 0, {
        layout: true, views: false
      })
    }, this)
    myApp.vent.on('webcast:inplay:choice:make', function () {
      var chosenItems = []
      for (var key in myApp.ent.inPlay.get('groupMembers')) {
        if (myApp.ent.inPlay.get('groupMembers')[key].chosen) {
          chosenItems.push(myApp.ent.inPlay.get('groupMembers')[key].itemId)
        }
      }
      if (_.size(chosenItems) < 1) {
        myApp.vent.trigger('ui:notification', {
          text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_GROUP_CHOICE_INSTRUCTION')
        })
        return
      }
      myApp.vent.trigger('ui:notification', {
        text: _.size(chosenItems) + ' ' + myApp.reqres.request('i16:getString', 'WebcastCodes_WC_GROUP_CHOICES_SENT_MESSAGE'), sticky: true
      })
      var params = [ myApp.ent.auction.get('auctionId'), myApp.ent.inPlay.get('itemId'), chosenItems.join(',') ]
      myApp.utils.ajax.post(params, actions.makeChoice, _.bind(function (response) {
        if (response.worked) {
          var models = myApp.request('reqres:webcast:models', response.models)
          myApp.vent.trigger('webcast:inplay:model:update', {
            models: models, code: response.actionCode
          })
        } else {
          myApp.vent.trigger('ui:notification', {
            text: response.message
          })
        }
      }, this))
    }, this)
    myApp.vent.on('webcast:inplay:place:bid', function (options) {
      // Disable the bid button before we receive the response to prevent
      // multiple bids being placed in cases of
      // slow networks or slow devices.
      $('#inPlayBidButton').prop('disabled', true)
      var amount = options.amount
      if (!amount || amount.length === 0) {
        return
      }
      var params = [ myApp.ent.auction.get('auctionId'), myApp.ent.inPlay.get('itemId'), myApp.ent.userRegistration.attributes.registrationId, amount ]
      myApp.utils.ajax.post(params, actions.placeBid, _.bind(function (response) {
        if (response.worked) {
          var models = myApp.request('reqres:webcast:models', response.models)
          myApp.vent.trigger('webcast:inplay:model:update', {
            models: models, code: response.actionCode
          })
        } else {
          myApp.vent.trigger('ui:notification', {
            text: response.message
          })
        }
      }, this))
    }, this)
    myApp.vent.on('webcast:inplay:mouse:over', function (options) {
      // Prevent the user spamming the mouseover. Allow only
      // 'maxMouseOverQueueSize' mouseover requests to be fired in 30
      // secs.
      var currentTime = new Date().getTime()
      // Clear queue of entries older than 30 secs
      while (mouseOverQueue[0] < (currentTime - timePeriodWindowLengthMs) && mouseOverQueue.length > 0) {
        mouseOverQueue.shift()
      }

      if (mouseOverQueue.length < maxMouseOverQueueSize || (!options.isOver && lastSentStateWasHovering)) {
        mouseOverQueue.push(currentTime)
        lastSentStateWasHovering = options.isOver
        var params = [ myApp.ent.auction.get('auctionId'), myApp.ent.inPlay.get('itemId'), options.isOver ]
        myApp.utils.ajax.post(params, actions.mouseOverBid, null)
      }
    }, this)
  }
  return {
    initialize: function (models) {
      myApp.vent.off('webcast:inplay:slider')
      myApp.vent.off('webcast:inplay:place:bid')
      myApp.vent.off('webcast:inplay:choice:make')
      myApp.vent.off('webcast:inplay:model:update')
      initEvents()
      initLightSliderEvent()
    },
    getCurrentBidAmount: function (passedIn) {
      if (myApp.ent.inPlay.isInPlay()) {
        return myApp.ent.inPlay.getCurrentBidAmount(passedIn)
      } else {
        return passedIn
      }
    }
  }
}
