PageApp.Ent.MakeChoiceModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (silently) {
    var buttonLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_CONFIRM_TEXT')
    var numbChosen = 0
    var units = 0
    var showNumberOfBids = myApp.ent.webcast2Settings.get('showNumberOfBids')

    for (var key in myApp.ent.inPlay.get('groupMembers')) {
      if (myApp.ent.inPlay.get('groupMembers')[key].chosen) {
        numbChosen++
        units += myApp.ent.inPlay.get('groupMembers')[key].numberOfUnits
      }
    }
    var value = 0
    if (myApp.ent.inPlay.get('biddingTypeEnumId') === 1) {
    // one money - should never come here
    } else if (myApp.ent.inPlay.get('biddingTypeEnumId') === 2) { // per item
      value = units * myApp.ent.inPlay.get('ringDetails').currentBid.amount
    } else if (myApp.ent.inPlay.get('biddingTypeEnumId') === 3) { // per lot
      value = numbChosen * myApp.ent.inPlay.getCurrentBidAmount()
    }

    var buttonClass = 'btn-default'
    if (numbChosen > 0) {
      buttonClass = 'btn-primary'
      buttonLabel += '<br/><span class="w2-choice-options">' + numbChosen + ' Selected = '
      if (!myApp.ent.user.attributes.currencySymbolAsPrefix) {
        buttonLabel += myApp.utils.types.toFormattedNumber(value / 100, myApp.ent.user.attributes.thousandsSeparator) + myApp.ent.inPlay.getCurrencySymbol()
      } else {
        buttonLabel += myApp.ent.inPlay.getCurrencySymbol() + myApp.utils.types.toFormattedNumber(value / 100, myApp.ent.user.attributes.thousandsSeparator)
      }
      buttonLabel += '</span>'
    }

    var content = {
      currentBidMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_HIGHEST_BID_TEXT'),
      currentFormatted: myApp.ent.inPlay.attributes.ringDetails.currentBid.amountFormatted,
      currentUsername: myApp.ent.inPlay.attributes.ringDetails.currentBid.bidderName,
      currentBidCount: (showNumberOfBids) ? '- ' + myApp.ent.inPlay.getBidCountMessage() : '',
      buttonLabel: buttonLabel,
      buttonClass: buttonClass
    }
    this.set(content, { silent: silently })
  }
})
