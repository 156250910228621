PageApp.Ent.AudioVideoStream = Backbone.Model.extend({
  isFeedVisible: function () {
    return this.attributes.feedVisible ? this.attributes.feedVisible : false
  },
  isFeedUrl: function () {
    return this.attributes.feedId ? this.attributes.feedId.indexOf('/') > -1 && this.attributes.feedId.indexOf('.') > -1 : false
  },
  isFeedActive: function () {
    var result = false
    var provider = this.attributes.feedProviderId
    if (provider && provider.length > 0) {
      if (this.attributes.tokSessionId && this.attributes.tokToken) {
        result = myApp.utils.webrtc.isStream()
      }
    }
    return result
  },
  streamDestroyed: function () {
    this.attributes.tokToken = undefined
    this.attributes.feedVisible = false
    myApp.ent.auction.get('feedModel').tokToken = undefined
    myApp.ent.auction.get('feedModel').feedVisible = false
  }
})
