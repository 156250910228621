PageApp.Ent.InPlayImagesModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (models, silently) {
    if (!models) return
    var imagelotNumber
    var theImages = []
    var imageLightboxSrc
    var sliderToggle = ''
    var noImageToggle = 'none'
    var imagePreviewSrc = 'none'
    var images = models.images
    var itemMessage = this.getItemMessage(myApp.ent.inPlay.attributes)
    for (var key in images) {
      var image = images[key]
      if (key === '0') {
        imagePreviewSrc = image.previewSrc
        imagelotNumber = image.lotNumber
        imageLightboxSrc = image.lightboxSrc
      }
      theImages.push({
        imageId: image.imageId,
        lotNumber: image.lotNumber,
        imageThumbSrc: image.thumbSrc,
        imageLightboxSrc: image.lightboxSrc,
        imagePreviewSrc: image.previewSrc,
        itemMessage: itemMessage
      })
    }
    if (imagePreviewSrc === 'none') {
      sliderToggle = 'none'
      noImageToggle = ''
    }
    var content = {
      imagePreviewSrc: imagePreviewSrc,
      theImages: theImages,
      imagelotNumber: imagelotNumber,
      imageLightboxSrc: imageLightboxSrc,
      itemMessage: itemMessage,
      noImageToggle: noImageToggle,
      sliderToggle: sliderToggle

    }
    this.set(content, { silent: silently })
  },
  getItemMessage: function (pendingItem) {
    var msg = ''
    if (pendingItem.itemMessage && pendingItem.itemMessage.length > 0) {
      if (pendingItem.itemMessageWinnerOnly) {
        if (myApp.ent.status.isUserWinningOrWon()) {
          msg = pendingItem.itemMessage
        }
      } else {
        msg = pendingItem.itemMessage
      }
    }
    return msg
  }
})
