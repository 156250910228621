PageApp.Views.MakeChoiceView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.makeChoiceView,
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
    'click .x-confirm': 'handleMakeChoice'
  },
  handleMakeChoice: function (e) {
    e.preventDefault()
    myApp.vent.trigger('webcast:inplay:choice:make')
  }
})
