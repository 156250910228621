PageApp.Views.SellingItemsView = Marionette.CollectionView.extend({
  childView: PageApp.Views.SellingItemView,
  reorderOnSort: true,
  className: 'w2-sell-item-collection list-group margin-bottom--none',
  initialize: function (options) {
    this.collection = options.collection
    this.childViewOptions = { strings: options.strings }
  },
  events: {
    'click .x-maxbid-button': 'handleMaxBidAction',
    'keyup .x-maxbid-input': 'handleInputMaxBid'
  },

  getEmptyView: function () {
    if (myApp.ent.stateData.isSellingTabSelected()) {
      return PageApp.Views.NoSellingItemView
    } else if (myApp.ent.stateData.isClosedTabSelected()) {
      return PageApp.Views.NoClosedItemView
    }
  },

  isEmpty: function (collection) {
    var isSellingTab = myApp.ent.stateData.isSellingTabSelected()
    var isEmpty = true
    // Loop thru all items in the collection or until isEmpty is false
    for (var i = 0; i < collection.length && isEmpty; i++) {
      var item = collection.models[i].attributes
      // Note: collection passed from w2_items_module may contain nulls! So check for itemId.
      if (item.itemId) {
        if (isSellingTab) {
          // If we are on the selling tab then if anything is live then isEmpty is (set to) false [resulting in the loop being terminated]
          isEmpty = !item.live
        } else {
          // If we are on the closed tab then isEmpty is (set to) false if anything is not live (i.e. is closed)  [and the loop is terminated]
          isEmpty = item.live
        }
      }
    }
    // Handle special case on the selling tab.
    // All items may be closed but we don't want to call it empty if we are still waiting for a choice on the last item
    if (isSellingTab) {
      return isEmpty && !(myApp.ent.auction.inPlayEndedWaitingChoice() && myApp.ent.inPlay.isWinningOrWon())
    } else {
      return isEmpty
    }
  },
  renderAppend: function (page) {
    var $sicvElf = this
    for (var key in page) {
      var item = page[key]
      var siv = new PageApp.Views.SellingItemView({ id: 'webcasty_' + item.get('itemId'), className: 'row', parent: $sicvElf })
      $sicvElf.$el.append(siv.render(item).el)
    }
    return this
  },

  handleInputMaxBid: function (e) {
    e.preventDefault()
    var $maxBidInput = $(e.currentTarget)
    if ($maxBidInput.is(':disabled')) return

    var itemId = $maxBidInput.data('id')
    // Get the confirm button corresponding to the input field
    var $maxBidConfirm = $('#maxBidConfirm-' + itemId)

    var allowSubmit = myApp.ent.userRegistration && myApp.ent.userRegistration.get('registered')
    if (e.keyCode === 13 && allowSubmit) {
      this.handleMaxBidAction(e)
    } else {
      $maxBidInput.val(myApp.utils.types.toFormattedNumberLimited($maxBidInput.val(), myApp.ent.user.attributes.thousandsSeparator))
      if ($maxBidInput.val().length > 0 && allowSubmit) {
        $maxBidConfirm.removeAttr('disabled')
        $maxBidConfirm.removeClass('btn-default')
        $maxBidConfirm.addClass('btn-primary')
      } else {
        $maxBidConfirm.attr('disabled', 'disabled')
      }
    }
    return false
  },

  handleMaxBidAction: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    if ($target.is(':disabled')) return

    var itemId = $target.data('id')
    // Get the maxbid input corresponding to the button
    var $maxBidConfirm = $('#maxBidInput-' + itemId)

    var amount = myApp.utils.types.toNumericCharsOnly($maxBidConfirm.val(), myApp.ent.user.attributes.thousandsSeparator)
    if (amount.length > 0) {
      amount = amount * 100
      myApp.vent.trigger('items:placeMaxBid', { itemId: itemId, amount: amount })
    }
    return false
  }

})
