PageApp.Ent.InPlayLayoutModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (models, responseCode, silently, exchangeRatesModel) {
    if (!models) return
    var makeChoiceView = false
    var makeNumericalChoiceView = false
    var choiceMadeView = false
    var bidView = false

    var feedRefreshRequired = true
    if (this.get('feedModel')) {
      feedRefreshRequired = models.feedModel && !this.isFeedEqual(this.get('feedModel'), models.feedModel)
    }
    var requireImagesRefresh = false
    if (models.inPlayImagesModel ||
      responseCode === myApp.ent.status.getRabbitResponseByName('ADMIN_RING_ACTION_MESSAGED_INTERNET').id ||
      responseCode === myApp.ent.status.getRabbitResponseByName('ADMIN_RING_ACTION_MESSAGED_ROOM').id ||
      responseCode === myApp.ent.status.getRabbitResponseByName('ADMIN_AUCTION_ACTION_PAUSED').id) {
      requireImagesRefresh = true
    }
    var group = myApp.ent.inPlay.get('group')
    var won = myApp.ent.inPlay.isWon()
    var inPlayEndedWaitingChoice = myApp.ent.auction.get('inPlayEndedWaitingChoice')
    if (group && won && inPlayEndedWaitingChoice) {
      if (myApp.ent.inPlay.get('choiceEnumId') === 2) { // full choice
        makeChoiceView = true
      } else if (myApp.ent.inPlay.get('choiceEnumId') === 3) { // numerical order
        makeNumericalChoiceView = true
      }
    } else if (group && myApp.ent.inPlay.isWon() && myApp.ent.auction.get('inPlayEndedChosen')) {
      choiceMadeView = true
    } else {
      bidView = true
    }
    var showExchangeRates = _.size(exchangeRatesModel) > 0 || false
    if (myApp.ent.inPlay.getCurrentBidAmount(0) > 0) {
      // working around a glitch in the server code where exchange rates are sent in two differently named models depending on ajax/non ajax request.
      if (models.exchangeRatesModel && models.exchangeRatesModel.exchangeRates) {
        showExchangeRates = true
      } else if (models.webcast2ExchangeRatesModel && models.webcast2ExchangeRatesModel.exchangeRates) {
        showExchangeRates = true
      }
    }

    var showMaxBidView = true
    if ((group && myApp.ent.inPlay.isWon() && myApp.ent.auction.get('inPlayEndedWaitingChoice'))) {
      showMaxBidView = false
    }

    var timestamp = -1
    if (!silently) {
      timestamp = new Date().getTime()
    }

    var content = {
      timestamp: timestamp,
      inLiveBidding: myApp.ent.auction.get('inLiveBidding'),
      inPlayControls: myApp.ent.auction.get('inPlayControls'),
      inPlayEnded: myApp.ent.auction.get('inPlayEnded'),
      inPlayEndedAny: myApp.ent.auction.get('inPlayEndedAny'),
      inPlayEndedChosen: myApp.ent.auction.get('inPlayEndedChosen'),
      inPlayEndedConfirmed: myApp.ent.auction.get('inPlayEndedConfirmed'),
      inPlayEndedWaitingChoice: myApp.ent.auction.get('inPlayEndedWaitingChoice'),
      inPlayPaused: myApp.ent.auction.get('inPlayPaused'),
      lotNumber: myApp.ent.inPlay.get('lotNumber'),
      paused: myApp.ent.auction.isPaused(),
      inPlay: myApp.ent.inPlay.isInPlay(),
      requireImagesRefresh: requireImagesRefresh,
      feedRefreshRequired: feedRefreshRequired,
      showMaxBidView: showMaxBidView,
      makeChoiceView: makeChoiceView,
      makeNumericalChoiceView: makeNumericalChoiceView,
      choiceMadeView: choiceMadeView,
      bidView: bidView,
      showExchangeRates: showExchangeRates
    }
    this.set(content, { silent: silently })
  },
  isFeedEqual: function (feed1, feed2) {
    var feed1IsNull = !feed1 || !feed1.feedProviderId
    var feed2IsNull = !feed2 || !feed2.feedProviderId
    if (feed1IsNull && feed2IsNull) {
      return true
    } else if (!feed1IsNull && !feed2IsNull) {
      return feed1.feedProviderId === feed2.feedProviderId && feed1.feedId === feed2.feedId && feed1.feedType === feed2.feedType
    } else {
      return false
    }
  }
})
