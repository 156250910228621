PageApp.Views.VideoControlView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.videoControlView,
  initialize: function (options) {
    this.options = options
  },
  events: {
    'click #feedHideButton': 'handleFeedHide',
    'click #feedShowButton': 'handleFeedShow'
  },
  modelEvents: {
    'change': 'render'
  },
  serializeData: function () {
    var feedModel = myApp.ent.auction.get('feedModel')
    var feedType = feedModel.feedType
    var feedHideButtonText = (feedType === 'AUDIO') ? myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_HIDE_AUDIO') : myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_HIDE_VIDEO')
    var feedShowButtonText = (feedType === 'AUDIO') ? myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_SHOW_AUDIO') : myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_SHOW_VIDEO')
    var displayHideFeedButton = this.model.isFeedActive() && this.model.isFeedVisible()
    var displayShowFeedButton = this.model.isFeedActive() && !this.model.isFeedVisible()
    var feedControlsMessage = feedModel.message

    var content = {
      feedHideButtonText: feedHideButtonText,
      feedShowButtonText: feedShowButtonText,
      feedControlsMessage: feedControlsMessage,
      feedHideButtonVisibility: displayHideFeedButton,
      feedShowButtonVisibility: displayShowFeedButton
    }
    return content
  },

  handleFeedHide: function (e) {
    if (this.$el.find('#feedHideButton').hasClass('button-disabled')) {
      myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_VIDEO_UNAVAILABLE') })
    } else {
      myApp.vent.trigger('webcast:video:view:hide')
    }
  },

  handleFeedShow: function (e) {
    if (this.$el.find('#feedShowButton').hasClass('button-disabled')) {
      myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_VIDEO_UNAVAILABLE') })
    } else {
      myApp.vent.trigger('webcast:video:view:show')
    }
  }

})
