PageApp.Views.BidView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.bidView,
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
    'click .x-bid-action': 'handleBidAction'
  },
  serializeData: function () {
    return this.model.attributes
  },
  handleBidAction: function (e) {
    e.preventDefault()
    var $target = $('#inPlayBidButton')
    if ($target.is(':disabled')) return

    var action = $target.data('action')
    if (action === 'login') {
      myApp.router.navigate('#!/login', { trigger: true })
      return
    } else if (action === 'register') {
      myApp.router.navigate('#!/registerForAuction/' + myApp.ent.auction.get('auctionId'), { trigger: true })
      return
    } else if (action === 'placeBid') {
      myApp.vent.trigger('webcast:inplay:place:bid', { amount: $target.data('amount') })
    }

    return false
  }

})
