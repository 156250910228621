PageApp.Ent.Filters = Backbone.Model.extend({
  defaults: {
    type: 'auctions',
    layout: 'list',
    currentCountry: ''
  },
  getAuctionCount: function () {
    return this.get('countEvent') + this.get('countFeaturedEvent') + this.get('countWebcast') + this.get('countFeaturedWebcast')
  }
})
