PageApp.Controllers.WebcastRouter = function (config) {
  var model = new PageApp.Ent.BasicEntity()
  var hasConnection = true
  function handleLongPollResponse (responses) {
    if (responses) {
      var maxTimestamp = -1 // responses[(responses.length-1)];
      try {
        for (var key in responses) {
          var response = responses[key]
          if (response.responseTimestamp > maxTimestamp) maxTimestamp = response.responseTimestamp
          if (checkValidResponse(response)) {
            processPollResponse(response)
          }
        }
      } catch (e) {
      }

      if (!responses.worked || responses.failed) {
        // we only want to fire another longPoll if the connection is healthy
        if (hasConnection) {
          // if the long poll failed, wait to fire another long poll, to prevent flooding
          setTimeout(function () {
            performLongPoll(maxTimestamp)
          }, 1000)
        }
      } else {
        performLongPoll(maxTimestamp)
      }
    }
  }
  function processPollResponse (response) {
    if (response.worked && response.actionCode > 0 && response.actionCode < 900 && response.responseTimestamp !== -2) { // 900 plus are long poll timeouts which return null models...
      // note actionCode = 0 is reserved by the bulk of w2 responses - i.e. synchronous responses on the server side.
      var groupIdToRemove = myApp.ent.inPlay.get('itemId')
      var models = myApp.request('reqres:webcast:models', response.models)
      if (myApp.ent.inPlay) {
        try {
          if (models.inPlayModel || models.auctionModel) {
            var ubd = myApp.ent.inPlay.getUserBiddingData(myApp.ent.inPlay.attributes)
            myApp.ent.inPlay.set('userBiddingData', ubd)
            if (models.inPlayModel) {
              models.inPlayModel.userBiddingData = ubd
            }
          }
          if (models.sellingItemModel) {
            models.sellingItemModel.userBiddingData = myApp.ent.inPlay.getUserBiddingData(models.sellingItemModel)
          }
          var sellingItem
          if (models.sellingModel) {
            for (var key in models.sellingModel.liveItems) {
              sellingItem = models.sellingModel.liveItems[key]
              sellingItem.userBiddingData = myApp.ent.inPlay.getUserBiddingData(sellingItem)
            }
          }
          if (models.amendedItemsModel) {
            for (var key1 in models.amendedItemsModel.amendedItems) {
              sellingItem = models.amendedItemsModel.amendedItems[key1]
              sellingItem.userBiddingData = myApp.ent.inPlay.getUserBiddingData(sellingItem)
            }
          }
        } catch (e) {
          // some calls will have already worked out the ubd.
        }
      }
      var notification = { models: models, code: response.actionCode, groupIdToRemove: groupIdToRemove }
      myApp.vent.trigger('webcast:inplay:model:update', { models: models, code: response.actionCode })
      myApp.vent.trigger('webcast:itemlist:model:update', notification)
      if (models.feedModel) {
        myApp.vent.trigger('webcast:video:model:change', models.feedModel)
      }
    }
  }
  function getParams (responseTimestamp) {
    var registrantId = myApp.ent.userRegistration.getRegistrantId()
    var itemId = myApp.ent.inPlay.getItemId()
    return [myApp.ent.auction.get('auctionId'), itemId, registrantId, responseTimestamp]
  }

  function performLongPoll (timestamp) {
    myApp.utils.ajax.get(getParams(timestamp), myApp.utils.ajax.getApiEndpoint('webcast2/longPoll'), _.bind(function (response) {
      handleLongPollResponse(response)
    }, this))
  }
  function checkValidResponse (response) {
    var doRendering = true
    try {
      var currentItemId = myApp.ent.inPlay.attributes.itemId
      if (myApp.ent.inPlay.attributes.ringDetails) {
        var currentBidCount = myApp.ent.inPlay.attributes.ringDetails.bidHistory.length
        if (response.models && response.models.inPlayModel && currentItemId === response.models.inPlayModel.itemId) {
          doRendering = response.models.inPlayModel.ringDetails.bidHistory.length >= currentBidCount
        }
      }
    } catch (e) {
      if (console.log) console.log('not rendering: ' + e)
    }
    if (!doRendering) {
      if (console.log) console.log('not rendering: ' + response)
    }
    return doRendering
  }
  function initCommands () {
    myApp.reqres.setHandler('reqres:webcast:models', function (models) {
      if (models.webcast2SettingsModel) {
        if (!myApp.ent.webcast2Settings) { myApp.ent.webcast2Settings = new PageApp.Ent.Webcast2Settings() }
        myApp.ent.webcast2Settings.set(models.webcast2SettingsModel)
      }
      if (models.auctionModel) {
        if (!myApp.ent.auction) { myApp.ent.auction = new PageApp.Ent.Auction() }
        myApp.ent.auction.set(models.auctionModel)
      }
      if (!myApp.ent.inPlay) { myApp.ent.inPlay = new PageApp.Ent.SellingItem() }
      if (models.inPlayModel) {
        var groupMembers = myApp.ent.inPlay.get('groupMembers')
        myApp.ent.inPlay.set(models.inPlayModel)
        if (!models.inPlayModel.withGroupMembers) {
          myApp.ent.inPlay.set('groupMembers', groupMembers)
          models.inPlayModel.groupMembers = groupMembers
        }
      }
      if (!myApp.ent.userRegistration) { myApp.ent.userRegistration = new PageApp.Ent.UserRegistration() }
      if (models.userRegistrationModel) {
        if (myApp.ent.user && models.userRegistrationModel.userId === myApp.ent.user.get('id')) {
          myApp.ent.userRegistration.set(models.userRegistrationModel)
        }
      }
      return models
    })
  }
  function initEvents () {
    myApp.vent.once('webcast:display', function (models) {
      var initiatedModels = myApp.request('reqres:webcast:models', models)
      model = new PageApp.Ent.BasicEntity(initiatedModels)
      var view = new PageApp.Views.WebcastLayout({ model: model })
      myApp.mainRegion.once('show', function (view) {
        view.titleRegion.show(new PageApp.Views.AuctionTitleView({ model: myApp.ent.auction }))
        view.messageRegion.show(new PageApp.Views.AppMessageView({ model: myApp.ent.auction }))

        this.inPlayRouter = new PageApp.Controllers.WebcastInPlayRouter()
        this.inPlayRouter.initialize()
        myApp.vent.trigger('webcast:inplay:display', { models: initiatedModels, region: view.webcastRegion })

        this.itemListRouter = new PageApp.Controllers.WebcastItemListRouter()
        this.itemListRouter.initialize()
        myApp.vent.trigger('webcast:itemlist:display', { models: initiatedModels, region: view.w2ItemsRegion })

        myApp.vent.trigger('webcast:polling:start')

        this.videoRouter = new PageApp.Controllers.WebcastVideoRouter()
        this.videoRouter.initialize()
        myApp.vent.trigger('webcast:video:model:display', { controlsRegion: view.w2VideoRegion })
      })
      myApp.mainRegion.show(view)
    }, this)
    myApp.vent.on('webcast:polling:start', function (models) {
      myApp.utils.ajax.get(getParams(0), myApp.utils.ajax.getApiEndpoint('webcast2/longPoll'), _.bind(function (response) {
        handleLongPollResponse(response)
      }, this))
    }, this)
  }
  return {
    initialize: function (models) {
      initCommands()
      initEvents()
    }
  }
}
