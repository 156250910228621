PageApp.Views.VideoLayoutView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.videoLayout,
  initialize: function (options) {
    this.options = options
  },
  regions: {
    videoControlRegion: '#videoControlRegion',
    videoDisplayRegion: '#videoDisplayRegion'
  },
  onRender: function () {
    try {
      if (!this.model.isFeedActive()) {
        this.videoControlRegion.empty()
        this.videoDisplayRegion.empty()
      } else {
        this.videoControlRegion.show(new PageApp.Views.VideoControlView({ model: this.model }))
        this.videoDisplayRegion.show(new PageApp.Views.VideoStreamView({ model: this.model }))
      }
    } catch (e) {
      if (console) console.log('error setting up feed..', e)
    }
  }
})
