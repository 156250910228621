PageApp.Ent.StateData = Backbone.Model.extend({
  defaults: {
    'groupsShowing': {},
    'chosen': {}
  },
  initialize: function () {
  },
  isGroupShowing: function (itemId) {
    return this.attributes.groupsShowing[itemId]
  },
  addGroupShowing: function (itemId) {
    this.attributes.groupsShowing[itemId] = true
  },
  removeGroupShowing: function (itemId) {
    delete this.attributes.groupsShowing[itemId]
  },
  toggleChosen: function (itemId) {
    if (this.attributes.chosen[itemId]) {
      delete this.attributes.chosen[itemId]
    } else {
      this.attributes.chosen[itemId] = true
    }
  },
  isSellingTabSelected: function () {
    return this.get('currentTab') === 'selling'
  },
  isClosedTabSelected: function () {
    return this.get('currentTab') === 'closed'
  }
})
