PageApp.Ent.Auction = Backbone.Model.extend({
  idAttribute: 'auctionId',
  url: function () {
    // Prevent cache-ing of ajax requests within IE by appending a unique value to each request.
    var randVal = (new Date()).getTime() + Math.floor((Math.random() * 1000000) + 1)
    var url = myApp.ent.config.attributes.contextPath + '/webcast2/auction/' + this.get('auctionId')
    url += '?nocache=' + randVal
    return url
  },
  inPreBidding: function () {
    return this.get('inPreBidding') // driven by start date - equiv to state DEFINED.
  },
  inPlayEndedAny: function () {
    return this.get('inPlayEndedAny')
  },
  inPlayPaused: function () {
    return this.get('inPlayPaused')
  },
  inPlayEndedConfirmed: function () {
    return this.get('inPlayEndedConfirmed')
  },
  inPlayEndedWaitingOrChosen: function () {
    return this.get('inPlayEndedWaitingChoice') || this.get('inPlayEndedChosen')
  },
  inPlayEndedWaitingChoice: function () {
    return this.get('inPlayEndedWaitingChoice')
  },
  inDefinedEndedSuspended: function () {
    return this.get('definedOrStarted') || this.get('inPlayEnded') || this.get('inPlayEndedConfirmed') || this.get('suspended')
  },
  disabledButtons: function () {
    return this.get('inPlayEndedAny') || this.get('countingDown')
  },
  isEndedOrPaused: function () {
    return this.inPlayEndedAny() || this.inPlayPaused() || this.get('auctionPaused')
  },
  isPaused: function () {
    return this.get('auctionPaused')
  }
})
