PageApp.Views.ItemMessageView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemMessageView,
  events: {
    'click #lotMessage .close': 'handleMessageClose'
  },
  modelEvents: {
    'change': 'render'
  },
  handleMessageClose: function () {
    $('#lotMessage').hide()
  }
})
