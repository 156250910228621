PageApp.Ent.ChoiceMadeModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (silently) {
    var viewTitle
    var chosenItems = []
    for (var key in myApp.ent.inPlay.get('groupMembers')) {
      if (myApp.ent.inPlay.get('groupMembers')[key].chosen) {
        chosenItems.push({
          lotNumber: myApp.ent.inPlay.get('groupMembers')[key].lotNumber,
          title: myApp.ent.inPlay.get('groupMembers')[key].title
        })
      }
    }
    if (chosenItems.length < 2) {
      viewTitle = chosenItems.length + ' Item Chosen'
    } else {
      viewTitle = chosenItems.length + ' Items Chosen'
    }
    var content = {
      viewTitle: viewTitle,
      chosenItems: chosenItems,
      message: 'Sent to the auctioneer for confirmation.'
    }
    this.set(content, { silent: silently })
  }
})
