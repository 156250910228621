PageApp.Ent.ItemMessageModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (silently) {
    var itemMessage = this.getItemMessage(myApp.ent.inPlay.attributes)
    var content = {
      itemMessage: itemMessage

    }
    this.set(content, { silent: silently })
  },
  getItemMessage: function (pendingItem) {
    var msg = ''
    if (pendingItem.itemMessage && pendingItem.itemMessage.length > 0) {
      if (pendingItem.itemMessageWinnerOnly) {
        if (myApp.ent.status.isUserWinningOrWon()) {
          msg = pendingItem.itemMessage
        }
      } else {
        msg = pendingItem.itemMessage
      }
    }
    return msg
  }
})
