PageApp.Controllers.AccountRouter = function () {
  function initEvents () {
    myApp.vent.on('account:activate:success', function (response) {
      var model = new PageApp.Ent.BasicEntity(response)
      model.set('JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED'))
      model.set('JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED_MESSAGE', myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED_MESSAGE'))
      myApp.mainRegion.show(new PageApp.Views.CreateAccountActivatedView({ model: model }))
    }, this)
    myApp.vent.on('account:forgotten:success', function (response) {
      var model = new PageApp.Ent.ForgottenPassword(response)
      myApp.mainRegion.show(new PageApp.Views.ForgottenPasswordSuccessView({ model: model }))
    }, this)
    myApp.vent.on('account:forgotten:display', function (response) {
      var model = new PageApp.Ent.ForgottenPassword(response)
      myApp.mainRegion.show(new PageApp.Views.ForgottenPasswordView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:display', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:failure', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordFailureView({ model: model }))
    }, this)
    myApp.vent.on('account:change:password:success', function (response) {
      var model = new PageApp.Ent.ChangePassword(response)
      myApp.mainRegion.show(new PageApp.Views.ChangePasswordSuccessView({ model: model }))
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
