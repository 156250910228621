PageApp.Views.ChangePasswordView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.changePassword,
  initialize: function (options) {
    this.options = options
  },
  ui: {
    password: '.x-password',
    passwordConfirm: '.x-confirm',
    progress: '.x-progress',
    token: '.x-token'
  },
  serializeData: function () {
    var data = {
      JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD'),
      JspPublicCodes_JSP_CHANGE_PASSWORD_REPEAT_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CHANGE_PASSWORD_REPEAT_PASSWORD'),
      JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD_STRENGTH: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CHANGE_PASSWORD_PASSWORD_STRENGTH'),
      JspPublicCodes_JSP_CHANGE_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CHANGE_PASSWORD'),
      JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD'),
      password: this.model.get('password'),
      passwordConfirm: this.model.get('passwordConfirm'),
      token: this.model.get('token'),
      validPasswordChange: this.model.get('valid')
    }
    return data
  },
  events: {
    'click .x-submit': 'handleSubmit'
  },
  onRender: function () {
    var $cpvElf = this
    this.ui.password.complexify({}, function callback (valid, complexity) {
      $cpvElf.ui.progress.css('width', complexity + '%')
      if (complexity > 30) {
        $cpvElf.ui.progress.addClass('progress-bar-success')
      }
    })

    if (!this.errors) return
    this.errors.each(function (element) {
      if (element.get('field') && element.get('field') === 'password') {
        this.ui.password.parent().find('span').html(element.get('message'))
        this.ui.password.parent().addClass('has-error')
      } else if (element.get('field') && element.get('field') === 'passwordConfirm') {
        this.ui.passwordConfirm.parent().find('span').html(element.get('message'))
        this.ui.passwordConfirm.parent().addClass('has-error')
      } else {
        var $ge = this.$el.find('#globalError')
        $ge.parent().addClass('has-error')
        $ge.html(element.get('message'))
      }
    }, this)
  },
  handleSubmit: function (e) {
    e.preventDefault()
    this.model.set('password', this.ui.password.val())
    this.model.set('passwordConfirm', this.ui.passwordConfirm.val())
    this.model.set('token', this.ui.token.val())
    this.errors = this.model.validate()
    if (this.errors) {
      this.render()
      return
    }
    myApp.utils.ajax.postPut(this.model.attributes, myApp.utils.ajax.getApiEndpoint('changePassword'), function (response) {
      var errors = response.errors
      if ((errors && errors.length > 0) || response.failed) {
        myApp.utils.validation.renderErrors(new PageApp.Ent.Errors(errors))
      } else {
        myApp.vent.trigger('account:change:password:success')
      }
    })
    return false
  }

})
