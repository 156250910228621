PageApp.Views.CalendarSidebarView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.calendarSidebar,
  events: {
    'click .x-auction': 'handleDisplayAuction'
  },
  modelEvents: {
    'change': 'modelChanged'
  },
  modelChanged: function (e) {
    this.render()
  }
})
