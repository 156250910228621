PageApp.Views.NoClosedItemView = Backbone.Marionette.ItemView.extend({

  template: hbs.templates.noClosedItemView,

  serializeData: function () {
    var content = {
      noClosedItemsMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_NO_CLOSED')
    }
    return content
  }

})
