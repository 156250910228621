PageApp.Ent.MessagesModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (inPlay, silently) {
    if (!inPlay) return
    var myClass = ''
    var winningWonClass = 'alert alert-success'
    var losingLostClass = 'alert alert-danger'
    var infoRequiredClass = 'alert alert-info'
    var warningAccountClass = 'alert alert-warning'

    var userBiddingData = inPlay.getUserBiddingData(inPlay.attributes)
    var userStatus = myApp.ent.userRegistration.attributes.status
    var message = userBiddingData ? userBiddingData.statusMessage : ''

    if (inPlay.isWinningOrWon()) {
      myClass = winningWonClass
    } else if (inPlay.isLosingOrLost()) {
      myClass = losingLostClass
    } else if (userBiddingData.userBiddingStatus === 6 || userBiddingData.userBiddingStatus === 7 || userBiddingData.userBiddingStatus === 9) {
      myClass = losingLostClass
    } else {
      myClass = 'alert alert-info'
    }
    if (userStatus === 6 || userStatus === 4 || userStatus === 0) {
      myClass = infoRequiredClass
    }
    if (userStatus === 3) {
      myClass = warningAccountClass
    }

    var content = {
      myClass: myClass,
      message: message

    }
    this.set(content, { silent: silently })
  }
})
