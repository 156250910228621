PageApp.Views.MessagesView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.messagingView,
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
  }
})
