PageApp.Views.MakeNumericalChoiceView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.makeNumericalChoiceView,
  initialize: function (options) {
    this.options = options
  },
  modelEvents: {
    'change': 'render'
  },
  events: {
    'keyup #wcNumberRequired': 'handleMakeChoiceKeyup',
    'click #wcNumberRequiredSubmit': 'handleMakeChoice'
  },
  onRender: function () {
    if (this.model.get('numbChosen') > 0) {
      this.ui.numberRequired.val(this.model.get('numbChosen'))
    }
  },
  ui: {
    numberRequired: '#wcNumberRequired'
  },
  handleMakeChoiceKeyup: function (e) {
    e.preventDefault()
    this.ui.numberRequired.val(myApp.utils.types.toNumericCharsOnly(this.ui.numberRequired.val()).substr(0, 10))
    return false
  },

  handleMakeChoice: function (e) {
    e.preventDefault()
    if (!this.validateInput()) {
      this.render()
      this.ui.numberRequired.focus()
      this.ui.numberRequired.select()
      return
    }
    var numb = myApp.utils.types.toNumber(this.ui.numberRequired.val())
    for (var i = 0; i < numb; i++) {
      myApp.ent.inPlay.get('groupMembers')[i].chosen = true
    }
    myApp.vent.trigger('webcast:inplay:choice:make')
  },
  validateInput: function () {
    var numb
    var valid = true
    try {
      numb = myApp.utils.types.toNumber(this.ui.numberRequired.val())
      if (numb < this.model.get('minimum')) {
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_NUMERIC_TOO_FEW') + ' ' + this.model.get('minimum'), level: 'danger' })
        valid = false
      } else if (numb > this.model.get('maximum')) {
        myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_NUMERIC_TOO_MANY') + ' ' + _.size(this.model.get('groupMembers')), level: 'danger' })
        valid = false
      }
    } catch (ex) {
      myApp.vent.trigger('ui:notification', { text: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_NOT_A_NUMBER'), level: 'danger' })
      valid = false
    }
    return valid
  }
})
