PageApp.Ent.ForgottenPassword = Backbone.Model.extend({
  defaults: {
    '@class': 'com.bidlogix.scaffold.models.account.ForgottenPasswordModel',
    email: ''
  },
  validate: function () {
    var errors = new PageApp.Ent.Errors()
    var error = null
    if (this.get('email').length === 0) {
      error = {
        field: 'email',
        message: myApp.reqres.request('i16:getString', 'InfrastructureCodes_NULL_VALUE_NOT_ALLOWED')
      }
      errors.add(error)
    } else if (this.get('email').indexOf('@') < 1) {
      error = {
        field: 'email',
        message: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_EMAIL_NOT_RECOGNISED')
      }
      errors.add(error)
    }

    if (errors.length === 0) {
      return false
    } else {
      return errors
    }
  }
})
