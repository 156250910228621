PageApp.Views.UpcomingAuctioneerView = Backbone.Marionette.ItemView.extend({
  initialize: function (options) {
    this.options = options
  },
  template: hbs.templates.upcomingAuctioneer,
  serializeData: function () {
    var ends = myApp.reqres.request('i16:getString', 'JspPublicCodes_AUCTION_ENDS')
    var m1 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FEATURED_AUCTIONS')
    var m2 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_EVENT_AUCTIONS')
    var m3 = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_WEBCAST_AUCTIONS')
    var upcomingAuctions = this.model.upcomingAuctions
    var upcomingFeaturedAuctions = this.model.upcomingFeaturedAuctions

    var unfeaturedMessage = ''
    if (this.model.countEvent > 0 || this.model.countWebcast > 0) {
      if (this.model.countEvent > 0) {
        unfeaturedMessage += ' ' + this.model.countEvent + ' ' + m2
      }
      if (this.model.countWebcast > 0) {
        unfeaturedMessage += ' ' + this.model.countWebcast + ' ' + m3
      }
    }
    var featuredMessage = ''
    if (this.model.countFeaturedEvent > 0 || this.model.countFeaturedWebcast > 0) {
      featuredMessage = m1
      if (this.model.countFeaturedEvent > 0) {
        featuredMessage += ' ' + this.model.countFeaturedEvent + ' ' + m2
      }
      if (this.model.countFeaturedWebcast > 0) {
        featuredMessage += ' ' + this.model.countFeaturedWebcast + ' ' + m3
      }
    }
    var itemMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    var itemsMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')
    for (var key in upcomingAuctions) {
      upcomingAuctions[key].message = (upcomingAuctions[key].itemCount > 1) ? itemsMessage : itemMessage
    }
    for (var key2 in upcomingFeaturedAuctions) {
      upcomingFeaturedAuctions[key2].message = (upcomingFeaturedAuctions[key2].itemCount > 1) ? itemsMessage : itemMessage
    }
    var isAuctioneerHome
    var homeCalToggle
    if (myApp.ent.config.get('page') === 'home') {
      isAuctioneerHome = true
      homeCalToggle = 9
    } else {
      isAuctioneerHome = false
      homeCalToggle = 12
    }
    return {
      logoDims: { width: 96, height: 54, operation: 'pad' },
      logoDimsLg: { width: 320, height: 210, operation: 'pad' },
      homeCalToggle: homeCalToggle,
      isAuctioneerHome: isAuctioneerHome,
      ends: ends,
      featuredMessage: featuredMessage,
      unfeaturedMessage: unfeaturedMessage,
      contextPath: myApp.ent.config.get('contextPath'),
      upcomingAuctions: upcomingAuctions,
      upcomingFeaturedAuctions: upcomingFeaturedAuctions,
      liveAuctioneers: this.model.liveAuctioneers,
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE')
    }
  },
  onShow: function () {
    this.addRegistrationLinks(this.model.upcomingFeaturedAuctions)
    this.addRegistrationLinks(this.model.upcomingAuctions)
  },
  addRegistrationLinks: function (auctions) {
    var contextPath = myApp.ent.config.get('contextPath')
    for (var key in auctions) {
      var auction = auctions[key]
      if (auction.catalogue) {
        var link = '<span id="catalogueAuction' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_AUCTION') + '</span>'
        this.$el.find('#registration_' + auction.auctionId).html(link)
      } else {
        var regState = this.model.registeredFor[auction.auctionId]
        if (!regState) {
          link = '<a class="register-for-auction" id="registerForAuctionFromAuction' + auction.auctionId + '" href="' + contextPath + '/registerForAuction/' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_NOT_REGISTERED') + '</a>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        } else {
          link = '<span class="register-for-auction" id="auctionstatus' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_' + regState) + '</span>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        }
      }
    }
  }
})
