PageApp.Views.ForgottenPasswordSuccessView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.forgottenPasswordSuccess,
  serializeData: function () {
    var backToHomepageUrl = myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl ? myApp.ent.webApp.attributes.webAppLogin.externalBaseUrl : '#home'
    return {
      JspPublicCodes_JSP_APEX_HAS_SENT_EMAIL: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_APEX_HAS_SENT_EMAIL'),
      JspPublicCodes_JSP_CLICK_LINK_TO_CHANGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CLICK_LINK_TO_CHANGE'),
      JspPublicCodes_JSP_BACK_TO_HOMEPAGE: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BACK_TO_HOMEPAGE'),
      backToHomepageUrl: backToHomepageUrl
    }
  }
})
