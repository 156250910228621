PageApp.Ent.BidModel = Backbone.Model.extend({
  initialize: function () {
  },
  extract: function (models, silently) {
    if (!models) return
    var inPlay = myApp.ent.inPlay
    var ringDetails = inPlay.attributes.ringDetails
    var userRegistration = myApp.ent.userRegistration.attributes
    var myUserId = userRegistration.userId
    var showNumberOfBids = myApp.ent.webcast2Settings.get('showNumberOfBids')

    var buttonLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_BUTTON_LOGIN')
    var bidCalculation
    var buttonAction
    var buttonClass = 'btn-primary'
    var buttonState
    var origin
    if (myApp.ent.user.isLoggedIn()) {
      if (userRegistration.approved) {
        buttonLabel = null // will be reassigned below
        bidCalculation = ringDetails.bidCalculationButtonMessage
        buttonAction = 'placeBid'
        buttonClass = 'btn-primary'
        if (ringDetails.currentBid.userId === myUserId) {
          buttonState = 'disabled'
          buttonAction = 'none'
        } else {
          buttonClass = 'btn-primary'
          buttonState = '' // enable button
        }
        if (myApp.ent.auction.isEndedOrPaused() || myApp.ent.auction.attributes.currentStateName === 'SUSPENDED') {
          buttonState = 'disabled'
          buttonClass = 'btn-default'
          buttonAction = 'none'
        }
      } else {
        if (!userRegistration.registrationId || userRegistration.status === 5) {
          buttonLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_BUTTON_REGISTER')
          buttonAction = 'register'
          buttonState = '' // enable button
        } else {
          buttonLabel = userRegistration.statusLabel
          buttonAction = ''
          buttonState = 'disabled' // enable button
        }
      }
    } else {
      buttonAction = 'login'
      buttonState = '' // enable button
    }

    if (!buttonLabel || buttonLabel.length === 0) {
      buttonLabel = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_BUTTON_BID')
      buttonLabel += ' ' + myApp.ent.inPlay.attributes.ringDetails.askingPriceFormatted
    }

    if (inPlay.attributes.ringDetails.currentBid.reserve) {
      origin = '( FLOOR )'
    }
    if (!inPlay.attributes.ringDetails.currentBid.reserve) {
      origin = '( INTERNET )'
    }
    if (inPlay.attributes.ringDetails.currentBid.amount === 0) {
      origin = ''
    }
    if (userRegistration.status === 3) {
      buttonLabel = "<i class='fa fa-exclamation-triangle'></i>"
      buttonState = 'disabled'
      buttonClass = 'btn-default'
    }
    if (userRegistration.status === 4) {
      buttonLabel = "<i class='fa fa-exclamation-triangle'></i>"
      buttonState = 'disabled'
      buttonClass = 'btn-default'
    }
    if (userRegistration.status === 6) {
      buttonLabel = "<i class='fa fa-exclamation-triangle'></i>"
      buttonState = 'disabled'
      buttonClass = 'btn-default'
    }
    if (buttonLabel.indexOf('null') >= 0) {
      buttonLabel = ''
      bidCalculation = ''
    }
    var content = {
      currentBidMessage: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_HIGHEST_BID_TEXT'),
      currentFormatted: inPlay.attributes.ringDetails.currentBid.amountFormatted,
      currentBidCount: (showNumberOfBids) ? '- ' + inPlay.getBidCountMessage() : '',
      buttonLabel: buttonLabel,
      bidCalculation: bidCalculation,
      buttonClass: buttonClass,
      buttonAction: buttonAction,
      buttonState: buttonState,
      origin: origin,
      bidAmount: inPlay.getAskingPrice()
    }
    this.set(content, { silent: silently })
  }
})
