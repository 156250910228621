PageApp.Views.InPlayLayoutView = Backbone.Marionette.LayoutView.extend({
  template: hbs.templates.inPlayLayout,
  isOverBidButton: false,
  initialize: function (options) {
    this.options = options
  },
  events: {
    'mousemove #inPlayLayout': 'handleMouseMove'
  },
  modelEvents: {
    'change': 'render'
  },
  className: 'panel panel-default',
  regions: {
    emptyRegion: '#emptyView',
    itemMessageRegion: '#itemMessageRegion',
    imagesRegion: '#imagesView',
    lotTitleRegion: '#lotTitleRegion',
    messagesRegion: '#messagesView',
    bidRegion: '#bidView',
    // maxBidRegion: '#maxBidView',
    exchangeRatesRegion: '#exchangeRatesView'
  },
  onRender: function () {
    var layout = this.model.attributes
    if (layout.paused) {
      this.emptyRegion.show(new PageApp.Views.AuctionPausedView({ model: this.options.viewModels.auctionPausedModel }))
    } else if (layout.inPlay) {
      this.lotTitleRegion.show(new PageApp.Views.LotTitleView({ model: this.options.viewModels.lotTitleModel }))

      this.messagesRegion.show(new PageApp.Views.MessagesView({ model: this.options.viewModels.messagesModel }))

      if (layout.makeChoiceView) {
        this.bidRegion.show(new PageApp.Views.MakeChoiceView({ model: this.options.viewModels.makeChoiceModel }))
      } else if (layout.makeNumericalChoiceView) {
        this.bidRegion.show(new PageApp.Views.MakeNumericalChoiceView({ model: this.options.viewModels.makeNumericalChoiceModel }))
      } else if (layout.choiceMadeView) {
        this.bidRegion.show(new PageApp.Views.ChoiceMadeView({ model: this.options.viewModels.choiceMadeModel }))
      } else if (layout.bidView) {
        this.bidRegion.show(new PageApp.Views.BidView({ model: this.options.viewModels.bidModel }))
      }
      if (layout.showExchangeRates) {
        this.exchangeRatesRegion.show(new PageApp.Views.ExchangeRatesView({ collection: this.options.viewModels.exchangeRatesModel }))
      }
      // if (layout.showMaxBidView) {
      //   this.maxBidRegion.show(new PageApp.Views.MaxBidView({model: this.options.viewModels.maxBidModel}))
      // }
      this.itemMessageRegion.show(new PageApp.Views.ItemMessageView({ model: this.options.viewModels.itemMessageModel }))
      this.imagesRegion.empty()
      this.imagesRegion.show(new PageApp.Views.ImagesView({ model: this.options.viewModels.inPlayImagesModel }))
    } else {
      this.emptyRegion.show(new PageApp.Views.EmptyView())
    }
  },
  handleMouseMove: function (e) {
    // Note: Previously this 'bid button hover' related functionality was handled in the BidView but this did not allow a large enough area
    // for mouse-over events to be captured. Events were not being fired 100% of the time if the mouse was moved over the bid button quickly
    // which caused the hover indicator in the clerking screen to sometimes stick in the wrong state. Also switching from using mouseenter/leave
    // to using mousemove events provides greater reliability (albeit with some additional overhead). Using mousemove events over the
    // whole inPlayLayout area also better copes with the fact that events are not fired over a disabled element
    // (i.e. the bid button when the user has the highest bid). Other issues concerned with the BidView being re-shown at each render were also
    // avoided by handling the functionality here.
    var isOverNow = $('#inPlayBidButton:hover').length !== 0
    if (this.isOverBidButton !== isOverNow) {
      if (myApp.ent.userRegistration && myApp.ent.userRegistration.attributes.approved) {
        myApp.vent.trigger('webcast:inplay:mouse:over', { isOver: isOverNow })
      }
      this.isOverBidButton = isOverNow
    }
  }
})
