PageApp.Views.ItemDetailsView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.itemDetailsView,
  initialize: function (options) {
    this.options = options
  },
  ui: {
    openLightbox: '.x-open-light-box'
  },
  events: {
    'click .x-close-slide-menu': 'handleCloseSlideMenu',
    'click .x-change-tab-details': 'handleTabChange',
    'click .x-open-light-box': 'handleOpenLightbox'
  },

  onRender: function () {
    var location = this.model.itemDetails.itemLocationModel
    if (!location.validLatLong) {
      this.$el.find('#itemDetailsLocationLink').hide()
    }
    return false
  },

  serializeData: function () {
    var item = this.model
    var itemDetails = item.itemDetails

    var documents = itemDetails.itemDocumentsModel.documents
    var images = itemDetails.itemImagesModel.images
    var videos = itemDetails.itemVideosModel.videos

    var lotNumber = item.lotNumber
    var theImages = []
    var imageLightboxSrc
    var imagePreviewSrc = '?'
    var theDocs = []
    var theVideos = []

    for (var key in images) {
      var image = images[key]
      if (key === '0') {
        imagePreviewSrc = image.previewSrc
        imageLightboxSrc = image.lightboxSrc
      }
      theImages.push({
        imageId: image.imageId,
        lotNumber: image.lotNumber,
        imageThumbSrc: image.thumbSrc,
        imageLightboxSrc: image.lightboxSrc,
        imagePreviewSrc: image.previewSrc
      })
    }

    for (key in documents) {
      var doc = documents[key]
      var docIcon
      var label = doc.label
      var docFilename = doc.originalFilename
      if (label === docFilename) label = ''

      var docType = doc.originalFilename.substr(doc.originalFilename.length - 3)

      if (docType === 'doc' || docType === 'ocx') {
        docIcon = 'word'
      }
      if (docType === 'pdf') {
        docIcon = 'pdf'
      }
      if (docType === 'xls' || docType === 'lsx' || docType === 'csv') {
        docIcon = 'excel'
      }
      if (docType === 'ppt' || docType === 'ptx') {
        docIcon = 'powerpoint'
      }
      if (docType === 'txt' || docType === 'rtf') {
        docIcon = 'text'
      }

      theDocs.push({
        docPreviewSrc: doc.documentSrc,
        docId: doc.documentId,
        docFileName: docFilename,
        docIcon: docIcon,
        label: label
      })
    }

    for (key in videos) {
      var video = videos[key]
      var videoLabel = video.label
      var link = 'https://www.youtube.com/embed/' + video.link + '?rel=0'

      if (!videoLabel) videoLabel = link

      theVideos.push({
        link: link,
        label: videoLabel
      })
    }

    var address = itemDetails.itemLocationModel

    var content = {
      mapsApiKey: myApp.ent.config.getMapsAPIKey(),
      modal: this.options.modal,
      imagePreviewSrc: imagePreviewSrc,
      theImages: theImages,
      theDocs: theDocs,
      theVideos: theVideos,
      lotNumber: lotNumber,
      imageLightboxSrc: imageLightboxSrc,
      title: item.title,
      description: item.description,
      summary: item.summary,
      imageSrc: item.imageSrc ? item.imageSrc : '?', // Need to output ? for blank src otherwise img onerror not triggered in FF
      addressLines: address.addressLines,
      city: address.city,
      county: address.county,
      country: address.country,
      postcode: address.postcode,
      latLong: address.validLatLong,
      lot: myApp.reqres.request('i16:getString', 'WebcastCodes_WC_LOT_TEXT')
    }
    return content
  },
  handleCloseSlideMenu: function (e) {
    e.preventDefault()
    this.$el.empty()
    $('body').removeClass('smr-open')
    this.$el.find('#slideMenuMask').removeClass('mask')
    this.$el.empty()
    // Reformat the selling items incase some selling activity has taken place underneath the ItemDetailsView
    myApp.vent.trigger('items:notifyRequireSellingItemReformat')
    myApp.vent.trigger('items:requireRefreshSellingClosedList', myApp.ent.stateData.get('currentTab'))
  },
  handleOpenLightbox: function (e) {
    e.preventDefault()
    var $target = $(e.currentTarget)
    $target.ekkoLightbox({
      no_related: true,
      onShown: function () {
        var lightbox = this
        myApp.vent.on('lightbox:close', function () {
          lightbox.close()
        })
      }
    })
    return false
  }
})
