PageApp.Views.UpcomingMarketplaceView = Backbone.Marionette.ItemView.extend({
  initialize: function (options) {
    this.options = options
  },
  template: hbs.templates.upcomingMarketplace,
  serializeData: function () {
    var itemMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    var itemsMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')
    var viewAllLabel = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_MARKETPLACE_VIEW_ALL')

    var upcomingMarketplace = this.model.upcomingMarketplace
    var dims = myApp.ent.layoutHacks.getDimensionsHomeCal()
    dims.operation = myApp.ent.config.get('auctionImageTransformOperation')

    for (var key in upcomingMarketplace) {
      upcomingMarketplace[key].message = (upcomingMarketplace[key].itemCount > 1) ? itemsMessage : itemMessage
    }
    return {
      dims: dims,
      viewAllLabel: viewAllLabel,
      contextPath: myApp.ent.config.get('contextPath'),
      upcomingMarketplace: upcomingMarketplace,
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE'),
      maxWidth: dims.width
    }
  }
})
