PageApp.Views.UpcomingListView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.upcomingList,
  appendRegistrantStatusToAuction: function (auction) {
    return Object.assign({}, auction, {
      registrantStatus: this.model.registeredFor[auction.auctionId]
    })
  },
  appendStartTimeStringToAuction: function (auction) {
    if (auction.auctionStartTime && auction.endsOrStarts !== 'Started') {
      let startString
      if (auction.auctionStartTime < myApp.ent.config.getNormalisedTimeMillis()) {
        startString = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_BIDDING_STARTED')
      } else {
        startString = auction.auctionStartTimeString
      }
      return Object.assign({}, auction, {
        startTimeString: startString
      })
    }
    return auction
  },
  serializeData: function () {
    var upcoming
    var upcomingAuctions = this.filterByCountry(this.model.upcomingAuctions, this.model.country)
      .map(this.appendRegistrantStatusToAuction.bind(this))
      .map(this.appendStartTimeStringToAuction.bind(this))
    var upcomingFeaturedAuctions = this.filterByCountry(this.model.upcomingFeaturedAuctions, this.model.country)
      .map(this.appendRegistrantStatusToAuction.bind(this))
      .map(this.appendStartTimeStringToAuction.bind(this))

    var showFeaturedAuctions = (this.model.upcomingFeaturedAuctions.length > 0)
    var isCalendarView = (myApp.ent.config.attributes.page === 'calendar')
    var itemMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEM')
    var itemsMessage = myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_ITEMS')

    var showAuctionLogos = false
    try {
      showAuctionLogos = myApp.ent.webApp.attributes.showAuctionLogosOnLandingPages
    } catch (err) {
      showAuctionLogos = false
    }

    for (var key in upcomingAuctions) {
      var upcomingAuction = upcomingAuctions[key]
      upcomingAuction.message = (upcomingAuction.itemCount > 1) ? itemsMessage : itemMessage
      upcomingAuction.hasLogo = (showAuctionLogos && upcomingAuction.auctionLogo)
    }
    for (var key2 in upcomingFeaturedAuctions) {
      var upcomingFeaturedAuction = upcomingFeaturedAuctions[key2]
      upcomingFeaturedAuction.message = (upcomingFeaturedAuction.itemCount > 1) ? itemsMessage : itemMessage
      upcomingFeaturedAuction.hasLogo = (showAuctionLogos && upcomingFeaturedAuction.auctionLogo)
    }

    // Reduce the number of items for auctions that have a logo (if the webapp is set to show them)
    if (showAuctionLogos) {
      for (var i = 0; i < upcomingAuctions.length; i++) {
        upcoming = upcomingAuctions[i]
        if (upcoming.hasAuctionLogo && upcoming.upcomingItems.length > 11) {
          upcoming.upcomingItems = upcoming.upcomingItems.slice(0, 11)
        }
      }
      for (i = 0; i < upcomingFeaturedAuctions.length; i++) {
        upcoming = upcomingFeaturedAuctions[i]
        if (upcoming.hasAuctionLogo && upcoming.upcomingItems.length > 11) {
          upcoming.upcomingItems = upcoming.upcomingItems.slice(0, 11)
        }
      }
    }

    var dims = Object.assign(myApp.ent.layoutHacks.getDimensionsHomeCal(), {
      operation: myApp.ent.config.get('auctionImageTransformOperation')
    })

    var logoDims = Object.assign({}, dims, {
      operation: 'pad'
    })

    return {
      dims: dims,
      logoDims: logoDims,
      contextPath: myApp.ent.config.get('contextPath'),
      upcomingAuctions: upcomingAuctions,
      upcomingFeaturedAuctions: upcomingFeaturedAuctions,
      featuredAuctionsTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_FEATURED_AUCTIONS'),
      auctionsTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_HOMEPAGE_AUCTIONS'),
      auctionCalendarTitle: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AUCTION_CALENDER'),
      showFeaturedAuctions: showFeaturedAuctions,
      isCalendarView: isCalendarView,
      awaitingImageMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_AWAITING_IMAGE'),
      auctionRegistrantStatusMessage: {
        ISSUE_WITH_DEPOSIT: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_ISSUE_WITH_DEPOSIT'),
        NOT_REGISTERED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_NOT_REGISTERED'),
        PENDING: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_PENDING'),
        APPROVED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_APPROVED'),
        SUSPENDED: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_SUSPENDED'),
        AWAITING_DEPOSIT: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_AWAITING_DEPOSIT'),
        INSUFFICIENT_DATA: myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_INSUFFICIENT_DATA')
      },
      catalogueAuctionMessage: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_AUCTION'),
      showAuctionLogos: showAuctionLogos
    }
  },
  addRegistrationLinks: function (auctions) {
    var contextPath = myApp.ent.config.get('contextPath')
    for (var key in auctions) {
      var auction = auctions[key]
      var regState = this.model.registeredFor[auction.auctionId]
      var link
      if (auction.catalogue) {
        link = '<span id="catalogueAuction' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_CATALOGUE_AUCTION') + '</span>'
        this.$el.find('#registration_' + auction.auctionId).html(link)
      } else {
        if (!regState) {
          link = '<a class="register-for-auction" id="registerForAuctionFromAuction' + auction.auctionId + '" href="' + contextPath + '/registerForAuction/' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_NOT_REGISTERED') + '</a>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        } else {
          link = '<span class="register-for-auction" id="auctionstatus' + auction.auctionId + '">' + myApp.reqres.request('i16:getString', 'AuctionRegistrantStatus_' + regState) + '</span>'
          this.$el.find('#registration_' + auction.auctionId).html(link)
        }
      }
    }
  },
  filterByCountry: function (auctions, country) {
    if (!this.model.country || this.model.country === 'all') {
      return auctions
    }
    var filteredAuctions = []
    for (var key in auctions) {
      var location = auctions[key].location
      if (location.indexOf(this.model.country) > -1) {
        filteredAuctions.push(auctions[key])
      }
    }
    return filteredAuctions
  }
})
