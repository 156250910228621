PageApp.Controllers.HomeCalRouter = function () {
  var ent = {}
  function supportsSearch () {
    var webAppSupports = (myApp.ent.config.get('contextPath') === '/auctions') || (myApp.ent.config.get('contextPath') === '/auction-011') || (myApp.ent.config.get('contextPath') === '/auction-017')
    return webAppSupports
  }

  function initializeModels (models) {
    ent.filters = new PageApp.Ent.Filters()
    myApp.ent.user.setPreference('homecalLayout', 'list')
    if (myApp.ent.webApp.get('useGalleryLayoutForCalendar')) {
      myApp.ent.user.setPreference('homecalLayout', 'gallery')
    }
    ent.filters.set('countries', models.HomePageModel.upcomingModel.countries)
    ent.filters.set('countMarketplaceItems', models.HomePageModel.upcomingModel.countMarketplaceItems)
    ent.filters.set('countEvent', models.HomePageModel.upcomingModel.countEvent)
    ent.filters.set('countFeaturedEvent', models.HomePageModel.upcomingModel.countFeaturedEvent)
    ent.filters.set('countWebcast', models.HomePageModel.upcomingModel.countWebcast)
    ent.filters.set('countFeaturedWebcast', models.HomePageModel.upcomingModel.countFeaturedWebcast)
    if (!models) return
    if (models.HomePageModel) {
      ent.homePage = new PageApp.Ent.HomePage(models.HomePageModel)
    }
  }
  function initEvents () {
    myApp.vent.on('homecal:display', function (models) {
      if (models) {
        initializeModels(models)
      }
      this.homeLayout = new PageApp.Views.HomeLayout()
      var xcoord = window.pageXOffset || document.documentElement.scrollLeft
      var ycoord = window.pageYOffset || document.documentElement.scrollTop
      this.homeLayout.upcomingRegion.on('show', function (view) {
        window.scroll(xcoord, ycoord)
      })
      myApp.mainRegion.show(this.homeLayout)

      var layout = myApp.ent.user.getPreference('homecalLayout', { value: 'list' })
      if (myApp.ent.config.get('page') === 'home') {
        if (supportsSearch()) myApp.vent.trigger('search:render', { searchInputRegion: this.homeLayout.getRegion('searchRegion') })
        if (ent.homePage.get('layoutId') === 'AUCTIONEER_CENTRIC') {
          this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingAuctioneerView({ model: ent.homePage.get('upcomingModel') }))
        } else {
          this.homeLayout.getRegion('filtersRegion').show(new PageApp.Views.FiltersView({ model: ent.filters }))
          if (myApp.ent.config.get('contextPath') === '/auction-017') {
            this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingMarketplaceView({ model: ent.homePage.get('upcomingModel') }))
          } else {
            var type = myApp.ent.user.getPreference('homecalType', { value: 'auctions' })
            if (type === 'auctions') {
              if (layout === 'list') {
                this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingListView({ model: ent.homePage.get('upcomingModel') }))
              } else {
                this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingGalleryView({ model: ent.homePage.get('upcomingModel') }))
              }
            } else {
              this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingMarketplaceView({ model: ent.homePage.get('upcomingModel') }))
            }
          }
        }
      } else {
        if (ent.homePage.get('layoutId') === 'AUCTIONEER_CENTRIC') {
          this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingAuctioneerView({ model: ent.homePage.get('upcomingModel') }))
        } else {
          this.homeLayout.getRegion('filtersRegion').show(new PageApp.Views.FiltersView({ model: ent.filters }))
          if (layout === 'list') {
            this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingListView({ model: ent.homePage.get('upcomingModel') }))
          } else {
            this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingGalleryView({ model: ent.homePage.get('upcomingModel') }))
          }
        }
      }
      myApp.vent.trigger('application:rendered', { region: myApp.mainRegion })
    }, this)
    myApp.vent.on('homecal:toggleCountry', function (config) {
      var layout = myApp.ent.user.getPreference('homecalLayout', { value: 'list' })
      ent.filters.set('country', config.country)
      var model = ent.homePage.get('upcomingModel')
      model.country = config.country
      if (layout === 'list') {
        this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingListView({ model: model }))
      } else {
        this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingGalleryView({ model: model }))
      }
      this.homeLayout.getRegion('filtersRegion').show(new PageApp.Views.FiltersView({ model: ent.filters }))
    }, this)

    myApp.vent.on('homecal:toggleLayout', function () {
      var layout = myApp.ent.user.getPreference('homecalLayout', { value: 'list' })
      if (layout === 'list') {
        this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingListView({ model: ent.homePage.get('upcomingModel') }))
      } else {
        this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingGalleryView({ model: ent.homePage.get('upcomingModel') }))
      }
      this.homeLayout.getRegion('filtersRegion').show(new PageApp.Views.FiltersView({ model: ent.filters }))
    }, this)
    myApp.vent.on('homecal:toggleType', function () {
      var type = myApp.ent.user.getPreference('homecalType', { value: 'auctions' })
      var layout = myApp.ent.user.getPreference('homecalLayout', { value: 'list' })
      if (type === 'auctions') {
        if (layout === 'list') {
          this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingListView({ model: ent.homePage.get('upcomingModel') }))
        } else {
          this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingGalleryView({ model: ent.homePage.get('upcomingModel') }))
        }
      } else {
        this.homeLayout.getRegion('upcomingRegion').show(new PageApp.Views.UpcomingMarketplaceView({ model: ent.homePage.get('upcomingModel') }))
      }
      this.homeLayout.getRegion('filtersRegion').show(new PageApp.Views.FiltersView({ model: ent.filters }))
    }, this)
  }
  return {
    initialize: function () {
      initEvents()
    }
  }
}
