PageApp.Controllers.WebcastVideoRouter = function (config) {
  var ent = {}
  var myView
  var myRegion
  function initialiseStream () {
    if (myApp.ent.auction.get('feedModel').tokToken) {
      myApp.utils.webrtc.joinSession(myApp.ent.auction.get('feedModel'), myApp.vent)
    } else {
      myApp.utils.ajax.get([myApp.ent.auction.get('auctionId')], myApp.utils.ajax.getApiEndpoint('webcast2/tokToken'), function (feedModel) {
        myApp.ent.auction.set('feedModel', feedModel)
        myApp.utils.webrtc.joinSession(myApp.ent.auction.get('feedModel'), myApp.vent)
      })
    }
  }
  function renderControls () {
    myRegion.empty()
    ent.audioVideoStream = new PageApp.Ent.AudioVideoStream(myApp.ent.auction.get('feedModel'))
    if (ent.audioVideoStream.isFeedActive()) {
      myView = new PageApp.Views.VideoLayoutView({ model: ent.audioVideoStream })
      myRegion.on('show', function (view) {
        if (ent.audioVideoStream.isFeedActive()) {
          ent.audioVideoStream.set('feedVisible', true)
          myApp.utils.webrtc.startSubscribing(ent.audioVideoStream.attributes)
        }
      })
      myRegion.show(myView)
    }
  }
  function initEvents () {
    myApp.vent.on('webcast:video:model:display', function (config) {
      myRegion = config.controlsRegion
      myRegion.on('close', function (view) {
        view.destroy()
      })
      initialiseStream()
    }, this)
    myApp.vent.on('webcast:video:hide', function () {
      ent.audioVideoStream.set('feedVisible', false)
      myApp.utils.webrtc.stopSubscribing(ent.audioVideoStream.attributes)
    }, this)
    myApp.vent.on('webcast:video:show', function () {
      ent.audioVideoStream.set('feedVisible', true)
      myApp.utils.webrtc.startSubscribing(ent.audioVideoStream.attributes)
    }, this)
    myApp.vent.on('webcast:video:model:change', function (feedModel) {
      console.log('webcast: model changed, current session: ' + myApp.ent.auction.get('feedModel').tokSessionId)
      console.log('webcast: model changed, new session: ' + feedModel.tokSessionId)
      myApp.ent.auction.set('feedModel', feedModel)
      initialiseStream()
    }, this)
    myApp.vent.on('webrtc:stream:created', function () {
      console.log('webcast: new session: ' + myApp.ent.auction.get('feedModel').tokSessionId)
      var startedMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_STREAM_STARTED')
      myApp.vent.trigger('ui:notification', { text: startedMessage })
      myApp.utils.ajax.get([myApp.ent.auction.get('auctionId')], myApp.utils.ajax.getApiEndpoint('webcast2/tokToken'), function (feedModel) {
        myApp.ent.auction.set('feedModel', feedModel)
        renderControls()
      })
    }, this)

    myApp.vent.on('webrtc:stream:destroyed', function () {
      console.log('webcast: disconnect form session: ' + myApp.ent.auction.get('feedModel').tokSessionId)
      myApp.vent.trigger('ui:notification', { text: 'Auctioneer has stopped video/audio stream' })
      ent.audioVideoStream.streamDestroyed()
      renderControls()
    }, this)
    myApp.vent.on('webrtc:stream:connected', function (config) {
      console.log('webcast: connected to session: ' + myApp.ent.auction.get('feedModel').tokSessionId)
      // renderControls();
    }, this)

    myApp.vent.on('webrtc:stream:notSupported', function () {
      var notSupportedMessage = myApp.reqres.request('i16:getString', 'WebcastCodes_WC_FEED_STREAM_NOT_SUPPORTED')
      myApp.vent.trigger('ui:notification', { text: notSupportedMessage })
    }, this)
  }
  return {
    initialize: function (models) {
      initEvents()
    }
  }
}
