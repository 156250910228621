PageApp.Views.ChangePasswordFailureView = Backbone.Marionette.ItemView.extend({
  template: hbs.templates.changePasswordFailure,
  serializeData: function () {
    return {
      JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD'),
      JspPublicCodes_JSP_PASSWORD_ALREADY_CHANGED: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_PASSWORD_ALREADY_CHANGED'),
      JspPublicCodes_JSP_NEED_CHANGE_AGAIN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_NEED_CHANGE_AGAIN'),
      JspPublicCodes_JSP_NEW_PASSWORD_CHANGE_REQUEST: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_NEW_PASSWORD_CHANGE_REQUEST'),
      JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD_LOGIN: myApp.reqres.request('i16:getString', 'JspPublicCodes_JSP_UNABLE_TO_CHG_PASSWORD_LOGIN'),
      contextPath: myApp.ent.config.attributes.contextPath
    }
  }
})
